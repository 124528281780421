import React, { Component } from 'react'
import $ from 'jquery'

class FilterToggle extends Component {
	constructor() {
		super()

		this.handleChange = this.handleChange.bind(this)
		this.shouldBeDisabled = this.shouldBeDisabled.bind(this)

		this.state = {
			disabled: false,
		}
	}

	handleChange(e) {
		e.preventDefault()

		if (!this.state.disabled) {
			const $element = $(e.target)
			const $controlGroup = $element.parents('.toggle__items')
			const $fieldGroup = $element.parents('.toggle__item')
			const $radio = $fieldGroup.find('input').prop('checked', true)

			$controlGroup.find('input').prop('checked', false)
			$controlGroup.find('.toggle__label--selected').removeClass('toggle__label--selected')
			$fieldGroup.find('label').addClass('toggle__label--selected')

			if (typeof this.props.applyFilterFn === 'function') {
				this.props.applyFilterFn($radio.attr('name'), $radio.val())
			}
		}
	}

	shouldBeDisabled() {
		let shouldBeDisabled = false
		for (const field in this.props.fieldConfig) {
			const filterField = this.props.fieldConfig[field]

			if (filterField.requiredMetaField && !this.props.filters[field]) {
				shouldBeDisabled = true
			}
		}

		const noGageSupport = ['2.0VLS', '2.0VLSA', '2.0VLSES', '2.0VLSESA', '2.0VS', '2.0VSA', '2.0VSES', '2.0VSESA', '3.5VLS', '3.5VLSA']

		if (this.props.filters && this.props.filters.deckType && noGageSupport.includes(this.props.filters.deckType)) {
			shouldBeDisabled = true
		}

		return shouldBeDisabled
	}

	setDisabledState() {
		// when a field has a dependency
		if (this.props.filterFields[this.props.field].primaryFilter) {
			// get the current state
			const currentDisabledState = this.state.disabled

			// this field should be disabled when the dependent field has no entry in the current filters object
			//const shouldBeDisabled = ! this.props.filters.hasOwnProperty(dependentField);
			const shouldBeDisabled = this.shouldBeDisabled()

			//console.log(currentDisabledState, shouldBeDisabled);

			//console.log('shouldBeDisabled', shouldBeDisabled, currentDisabledState, dependentField, this.props.filters);
			// only change state when the current state doesn't match the new state
			if (currentDisabledState !== shouldBeDisabled) {
				//console.log('set to disabled');
				this.setState({
					disabled: shouldBeDisabled,
				})
			}
		}
	}

	getClassName(field, value) {
		var className = 'toggle__label '

		// if no filter setting exists
		// and there is a default value defined for this toggle component
		// and that value is the one being currently handled
		if (!this.props.filters[field] && this.props.fieldConfig[field].defaultValue && this.props.fieldConfig[field].defaultValue === value) {
			className += 'toggle__label--selected'
		} else if (this.props.filters[field] && this.props.filters[field] === value) {
			className += 'toggle__label--selected'
		}

		//console.log(this.props.filters[field]);
		//console.log(this.props.fieldConfig[field].defaultValue, field, value);

		return className
	}

	componentDidUpdate() {
		this.setDisabledState()
	}

	componentDidMount() {
		this.setDisabledState()
	}

	render() {
		return (
			<div
				className="toggle"
				style={{ opacity: this.state.disabled ? '0.5' : '1' }}>
				<div className="toggle__title">{this.props.fieldConfig[this.props.field].label}</div>
				<div className="toggle__items">
					{Object.keys(this.props.resultRowsFieldConfig[this.props.field].options).map((option) => (
						<div
							className="toggle__item"
							key={'field-group-' + this.props.field + '-' + option}>
							<input
								disabled={this.state.disabled}
								type="radio"
								name={this.props.field}
								key={'select-option-' + this.props.field + '-' + option}
								id={'select-option-' + this.props.field + '-' + option}
								value={option}
							/>
							<label
								disabled={this.state.disabled}
								onClick={this.handleChange}
								className={this.getClassName(this.props.field, option)}
								htmlFor={'select-option-' + this.props.field + '-' + option}>
								{option}
							</label>
						</div>
					))}
				</div>
			</div>
		)
	}
}

export default FilterToggle
