class Utils {

	static cloneObject(object)
	{
		return JSON.parse(JSON.stringify(object));
	}

	static enforceDataType(value)
	{
		if( !isNaN(value))
		{
			value = parseFloat(value);
		}
		return value;
	}

	static queryStringToObject(queryString)
	{
		const params = {
			'queryString': queryString
		};

		queryString.split('&').map( (segment) => {
			const [paramName, paramValue] = segment.split('=');
			params[paramName] = paramValue;
			return paramValue;
		});

		return params;
	}
}

export default Utils;