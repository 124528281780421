import React, { Component } from 'react';
import PageWrapper from '../PageWrapper.js';
import Filter from '../Filter.js';

export default class RoofDeck extends Component
{
	render()
	{
		// pageTitle={["Deck ", <span>Tools</span>]}>
		return <PageWrapper
			showTabs="true"
			activeTab="roof"
			banner="decktools"
			pageTitle={ <React.Fragment>Deck <span>Tools</span></React.Fragment> } >
				<Filter formName="Roof Deck" productType="Roof_Deck" />
	    </PageWrapper>
	}
}