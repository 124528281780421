import React, { Component } from 'react'
import FilterHeader from './FilterHeader.js'
import FilterResultRows from './FilterResultRows.js'
import $ from 'jquery'
import Utils from './Utils.js'
import Config from './Config.js'
import entries from 'object.entries'

if (!Object.entries) {
	entries.shim()
}

class Filter extends Component {
	constructor(props) {
		super()

		const config = new Config()

		this.config = config

		this.maintenanceMode = false

		// will store the formConfig for Deck Type and/or Deck Type / Diaphragm Design
		this.formConfig = config.getDeckConfig(props.productType)

		this.state = {
			activeItemId: null,

			activeDeckHtml: '',

			// matched Load Table documents for <FilterResultRows>
			loadTableMatches: {},

			// toggles view/filters between Load Tables & Diaphragm Design
			formMode: this.config.formMode.LOAD_TABLES,

			// applied filters
			filters: {},

			// relevant, top level fields for this deck (roof, form, composite)
			fieldConfig: this.formConfig.fields,

			// state representation of the matching rows of data; use in result rows as well as options (<option>) for each filter (<select>)
			resultRowsFieldConfig: {},

			// relevant diaphragm design fields for this deck (roof, form, composite)
			diaphragmDesignFieldConfig: this.formConfig.diaphragmDesignFieldConfig,

			// users selected filters for diaphragm design
			diaphragmDesignFilters: {},

			// state for selected diaphragm design field selections
			diaphragmDesignStateData: {},

			filteredResultCount: 0,

			// whether or not a filter is applied
			isFiltered: false,

			// whether the application is currently applying a filter
			isFiltering: false,

			matches: [],

			productType: props.productType,

			ready: 'not-ready',
		}

		this.productTypeMap = {
			Form_Deck: 'form',
			Roof_Deck: 'roof',
			Composite_Deck: 'composite',
		}

		this.baseUrl = config.getBaseUrl()

		this.endpoint = this.baseUrl + '/get-data.php?type=' + this.productTypeMap[props.productType]

		this.filterViewData = this.filterViewData.bind(this)
		this.applyFilter = this.applyFilter.bind(this)
		this.resetFilters = this.resetFilters.bind(this)
		this.getFieldConfigOptions = this.getFieldConfigOptions.bind(this)
		this.hasFiltersApplied = this.hasFiltersApplied.bind(this)
		this.getDeckData = this.getDeckData.bind(this)
		this.getDocumentId = this.getDocumentId.bind(this)
		this.selectDeck = this.selectDeck.bind(this)
		this.updateDiaphragmDesignState = this.updateDiaphragmDesignState.bind(this)
		this.getFilterFormWrapperClass = this.getFilterFormWrapperClass.bind(this)
		this.toggleFormMode = this.toggleFormMode.bind(this)
		this.isFilteringClassName = this.isFilteringClassName.bind(this)
		this.clearFilters = this.clearFilters.bind(this)
	}

	prepData(response, formMode, reset = false) {
		const maxMatches = 2000
		const matches = []

		// will contain a document-indexed hash of the matches for this type of deck/diaphragm design
		const resultRows = {}

		// field spec for Load Tables
		const fieldsForFormType = formMode === this.config.formMode.LOAD_TABLES ? this.formConfig.fields : this.formConfig.diaphragmDesignFieldConfig

		// will contain the field configuration for the resulting data (fields in the spec pertaining
		// to the subject search, options for selects, etc
		const resultRowsFieldConfig = Utils.cloneObject(fieldsForFormType)

		const filters = {}

		let count = 0

		let filteredResultCount = 0

		response.forEach((row) => {
			const itemId = row.id
			const meta = row.value

			let matched = true

			// DISABLING ANY IBC deck - we're only providing SDI RD Std at this point:
			// NM Notes:
			// Kurt noticed that the spreadsheet is not producing desired results for some of the table values when
			// Construction Load Criteria is set to “IBC”, which means that our spreadsheet is incorrect. He feels
			// that the “IBC” option is not needed for these commodity roof deck types, but may be needed for future
			// enhancements to the tool. Please keep functionality, but disable the “IBC” option or make the Construction
			// Load Criteria options not even display in current version.

			if (row.value.defaultConstructionLoads && row.value.defaultConstructionLoads === 'IBC') {
				return
			}

			if (!this.validateRowFields(meta, fieldsForFormType)) {
				matched = false
			}

			// add to results if it validated
			if (matched && matches.length <= maxMatches) {
				// This data is indexed by document id so create a new element in the data object which refers to this
				// particular document
				resultRows[itemId] = {
					id: itemId,
					value: meta,
				}
			}

			// then iterate over the field spec for this document and:
			// 1. add the "value" property to the resultRows object, which will be used to generate the results table
			// 2. append every relevant field into the resultRowsFieldConfig, which is used for the filters/<select>s
			//    in FilterHeader, etc

			for (const fieldName in fieldsForFormType) {
				const metaValue = meta[fieldName]

				//if( ! this.validate(fieldName, metaValue, meta, fieldsForFormType) )
				//{
				//	matched = false;
				//	continue;
				//}

				// default the <option> text and values to metaValue for now
				let optionText = metaValue
				let optionValue = metaValue

				// add this field value to the row, if the item validated
				if (matched && matches.length <= maxMatches) {
					// it used to be that we had Meta Filters and Primary Filters, actually it was really just Primary Filters;
					// then there became the need to distinguish a class of filters to prevent them from applying changes to the resulting
					// data because ALL decks come in Standard or Alternate; All decks come in ASD or LRFD;
					// and now a Meta Filter can also exist in the same row as Primary Filters and it WILL need to influence the rendered
					// set of data - so, if a "gage" filter is applied, and the data we're iterating over is for "Standard", but the user
					// has put the tool into an "Alternate" state, then switch the value because - again - ALL DECKS can be standard or alternate

					let resultRowValue = metaValue
					if (fieldName === 'gage' && resultRowValue === 'Standard' && this.state.filters['gage'] && this.state.filters['gage'] === 'Alternate') {
						resultRowValue = 'Alternate'
					}

					// and, there is no "Inverted" type deck in the data source, they're identical the non inverted types so instead of doubling the amount
					// of data we have, we just alias Inverted to non, but we still need to show the user's selected values in the UI
					if (fieldName === 'deckType' && this.state.filters['deckType']) {
						if (this.state.filters.deckType === '1.5CDR' || this.state.filters.deckType === '1.5FDR') {
							// add the "R" for "Inverted"
							resultRowValue += 'R'
						}
					}

					if (fieldName === 'netUplift' && resultRowValue === '') {
						resultRowValue = 'No Uplift'
					}

					// add a result row
					resultRows[itemId][fieldName] = {
						value: resultRowValue,
					}

					// when a deckType filter exists, supply a displayValue string instead of the default value provided in data
					// because a "1.0RD" is identified as "1.0RD, 1.0RDV", for instance, and we want to persist what the user selected
					// even if it isn't identified as such in data
					if (fieldName === 'deckType') {
						if (fieldsForFormType.deckType.options[resultRowValue] && fieldsForFormType.deckType.options[resultRowValue].text) {
							resultRows[itemId][fieldName].displayValue = fieldsForFormType.deckType.options[resultRowValue].text
						}
					}
				}

				// ensure an object is represented for this field item
				if (!resultRowsFieldConfig[fieldName]) {
					resultRowsFieldConfig[fieldName] = {
						id: itemId,
						label: fieldsForFormType[fieldName].label,
						options: {},
					}

					if (meta[fieldName].units) {
						resultRowsFieldConfig[fieldName].units = meta[fieldName].units
					}
				}

				// identify a default value in filters, if one exists for this field
				if (fieldsForFormType[fieldName].defaultValue) {
					filters[fieldName] = Utils.enforceDataType(fieldsForFormType[fieldName].defaultValue)
				}

				if (matched && matches.length <= maxMatches && fieldsForFormType[fieldName].keyValue) {
					resultRows[itemId][fieldName].keyValue = meta[fieldName + 'KeyValue']
				}

				//if( fieldsForFormType[fieldName])

				// if this field has a compoundField property, then the Text and Value of the <option> should reflect
				// the concatenated value of all those properties
				// but we should also store each one individually for validation purposes

				if (fieldsForFormType[fieldName].compoundFields) {
					let compoundFieldText = ''
					let compoundFieldValue = [fieldName + '=' + optionValue]

					// iterate the compound fields for this field
					fieldsForFormType[fieldName].compoundFields.forEach((compoundFieldName) => {
						const inchMark = (compoundFieldName === 'support4' || compoundFieldName === 'support3') && !isNaN(meta[compoundFieldName]) ? '"' : ''

						// get a handle on the value
						const compoundFieldNameValue = meta[compoundFieldName]

						// push value into array before we begin to modify the text value for prettyness
						compoundFieldValue.push(compoundFieldName + '=' + compoundFieldNameValue)

						// when the value is not an empty string, add an optional space for breathing room and
						// when appropriate, add an inch mark
						if (compoundFieldNameValue !== '') {
							const space = compoundFieldText === '' ? '' : ' '
							compoundFieldText += space + compoundFieldNameValue + inchMark
						}

						// empty or not, store a reference to the value of this field on the result row
						if (matched && matches.length <= maxMatches) {
							resultRows[itemId][compoundFieldName] = compoundFieldNameValue
						}
					})

					if (compoundFieldText !== '') {
						optionText += ' (' + compoundFieldText + ')'
						optionValue = compoundFieldValue.join('&')
					}

					// add a 'displayValue' field here as an alternative text value to show in the result rows
					// as a compound value, since this is a composite field, representing 2-3 fields
					if (matched && matches.length <= maxMatches) {
						// add a 'displayValue' field here as an alternative text value to show in the result rows
						// as a compound value, since this is a composite field, representing 2-3 fields
						resultRows[itemId][fieldName]['displayValue'] = optionText
					}
				}

				// add this <select> option text/value to the field config

				// switched from metaValue to optionText, otherwise the keys are not unique - since we're aggregating
				// compound field values, there will be more than a single "supportFastener", for instance so the fieldName
				// is not sufficiently unique

				let addOption = true

				// Don't add 50 or 80 when the decktype is 2.0VLSES
				if (fieldName === 'minYieldStressForGrade' && this.state.filters.deckType && this.state.filters.deckType === '2.0VLSES' && [50, 80].includes(optionValue)) {
					addOption = false
				}

				if (addOption) {
					resultRowsFieldConfig[fieldName].options[optionText] = {
						value: optionValue,
						text: optionText,
					}
				}
			}

			if (matched && matches.length <= maxMatches) {
				matches.push(resultRows[itemId])
				count += 1
				filteredResultCount += 1
			}
		})

		// const fieldOrderMap = this.formConfig[this.state.formMode + '_FieldOrderMap']
		const fieldOrderMap = this.formConfig[formMode + '_FieldOrderMap']
		const fieldConfigOptions = {}
		let fieldConfigData = response

		// let fieldConfigData = matches
		// We can't use matches, because that will prune previous filters - for instance, we've already selected 16 gage decks so `matches` now only consists
		// of those decks. If we refer to matches, we'll lose a reference to 18 gage decks making it impossible to change a selection without resetting the very first filter

		fieldOrderMap.forEach((field) => {
			const dependencyFieldIndex = fieldOrderMap.indexOf(field)
			const dependencyField = fieldOrderMap[dependencyFieldIndex - 1]

			const optionsForField = this.getFieldConfigOptions(fieldConfigData, field, dependencyField, fieldsForFormType)

			if (!fieldConfigOptions[field]) {
				fieldConfigOptions[field] = {}
				fieldConfigOptions[field].options = optionsForField.options
			}

			// I don't understand this. It looks like I'm progressively reducing fieldConfigData so that the matched documents
			// reflect what I have in filters
			// But it's breaking things for Versa Dek, where no Support Fasteners or Sidelap Fasteners get populated once a Min Yield value is selected

			// fieldConfigData = optionsForField.matches
		})

		if (formMode === this.config.formMode.LOAD_TABLES) {
			if (resultRowsFieldConfig.gage) {
				fieldConfigOptions['gage'].options = { Standard: { value: 'Standard', text: 'Standard' }, Alternate: { value: 'Alternate', text: 'Alternate' } }
			}
			if (resultRowsFieldConfig.designMethod) {
				fieldConfigOptions['designMethod'] = {}
				fieldConfigOptions['designMethod'].options = { ASD: { value: 'ASD', text: 'ASD' }, LRFD: { value: 'LRFD', text: 'LRFD' } }
			}
			if (resultRowsFieldConfig.concreteUnitWeight) {
				fieldConfigOptions['concreteUnitWeight'] = {}
				fieldConfigOptions['concreteUnitWeight'].options = { 'NW (145)': { value: '145', text: 'NW (145)' }, 'LW (110)': { value: '110', text: 'LW (110)' } }
			}

			// WE'RE REMOVING Default Construction Load
			// But maybe it'll come back at some point so I'm just disabling this:

			// IBC comes first but needs to be last because it's not the default
			// and, SDI RD Std needs to be renamed
			// so just rebuild the options object
			//if( resultRowsFieldConfig.defaultConstructionLoads )
			//{
			//	fieldConfigOptions.defaultConstructionLoads.options = {
			//		'SDI RD Std (Ind Std)': {text: 'SDI RD Std (Ind Std)', 'value': 'SDI RD Std'},
			//		'IBC': {text: 'IBC', 'value': 'IBC'}
			//	};
			//}

			;['unshoredSpans', 'totalSlabDepths'].forEach((field) => {
				if (fieldConfigOptions[field]) {
					const low = fieldConfigOptions[field].rangeLow
					const high = fieldConfigOptions[field].rangeHigh
					const step = field === 'unshoredSpans' ? 8 : 0.5

					fieldConfigOptions[field].options = {}

					for (let i = low; i <= high; i += step) {
						fieldConfigOptions[field].options[i] = {
							value: i,
							text: i,
						}
					}
				}
			})

			// deckType values are static - read from config
			//fieldConfigOptions['deckType'] = {};
			//fieldConfigOptions['deckType'].options = this.formConfig.fields.deckType.options;
		} else {
			if (fieldConfigOptions.fillType && fieldConfigOptions.fillType.options) {
				fieldConfigOptions.fillType.options = {
					'No Concrete': { value: 'No Concrete', text: 'No Concrete' },
					'NW Concrete': { value: 'NW Concrete', text: 'NW (145 pcf, 2" above)' },
					'LW Concrete': { value: 'LW Concrete', text: 'LW (110 pcf, 2" above)' },
				}
			}

			if (fieldConfigOptions.netUplift && fieldConfigOptions.netUplift.options) {
				const currentNetliftOptions = fieldConfigOptions.netUplift.options
				fieldConfigOptions.netUplift.options = {
					0: { value: 'NO_NETUPLIFT', text: 'No Uplift' },
				}

				for (const option in currentNetliftOptions) {
					// don't add the empty option, because that got substituted above with an option value of "NO_NETUPLIFT" so that comparisions are not boolean
					// and are string based instead.
					if (option !== '' && option !== false && option !== 'false') {
						fieldConfigOptions.netUplift.options[option] = currentNetliftOptions[option]
					}
				}
			}

			// deckType values are static - read from config
			fieldConfigOptions['deckType'] = {}
			fieldConfigOptions['deckType'].options = fieldsForFormType.deckType.options

			// Gage can't be calculated without a result and upon initial load, we won't have any
			// data at all because the api response for diaphram design without a decktype filter would be HUGE
			// so if a decktype hasn't been chosen yet, just manually purge anything that exists
			if (!this.state.filters.deckType) {
				fieldConfigOptions['gage'] = {}
				fieldConfigOptions['gage'].options = {}
			}
		}

		// Too bad I didn't add a comment about this after implementation because I no longer understand what this was all about
		// It kind of looks like I'm trying to reduce a set of filter options to just those which have matches in the current data set
		// So if a filter has excluded options for 40 Min Yield Stress, for instance, then we'd lose "40" from the MYSFG select

		// Unfortunately, this is blowing things up for the Versa Dek options, which lose valid selections
		for (let filterFieldItem in fieldConfigOptions) {
			if (resultRowsFieldConfig[filterFieldItem] && fieldConfigOptions[filterFieldItem].options) {
				resultRowsFieldConfig[filterFieldItem].options = fieldConfigOptions[filterFieldItem].options
			}
		}

		return {
			count: count,
			filteredResultCount: filteredResultCount,
			matches: matches,
			resultRows: resultRows,
			resultRowsFieldConfig: resultRowsFieldConfig,
		}
	}

	getFieldConfigOptions(data, fieldName, dependencyField, fieldsForFormType) {
		// this will be the reduced set of matches that will be returned to be sorted through
		// for the next filter
		const matches = []

		// and this will be the actual field options from this data set, that matched the current filter,
		// unless no filter exists in which case they all match
		const options = {}

		// the current filter value, if one exists
		let filterValue = this.state.filters[dependencyField] || false

		// iterate this subset of results and find the matches for this field
		data.forEach((row) => {
			let optionText = row.value[fieldName] || false
			let optionValue = row.value[fieldName] || false
			let dataRowValue = row.value[dependencyField]

			// flag will determine whether each constituent element of a compound field validated
			let hasCompoundFields = false
			let compoundFieldsValid = true

			// flag to determine whether a range input value validated
			let hasRangeInputs = false
			let rangeInputFieldValid = true

			if (optionText && optionValue) {
				// if the values are integer-like, convert them to actual integers for strict comparison
				if (!isNaN(optionValue) && optionValue !== false) {
					optionValue = parseInt(optionValue, 10)
				}
				if (!isNaN(filterValue) && filterValue !== false) {
					filterValue = parseInt(filterValue, 10)
				}
				if (!isNaN(dataRowValue) && dataRowValue !== 'false' && dataRowValue !== '') {
					// Net Uplift "empty" values are "false", as a string
					dataRowValue = parseInt(dataRowValue, 10)
				}
			}

			// Standard and Alternate are not differentiated in the database, there is one for each permutation. So if the user is searching by "Alternate"
			// just switch the dataRowValue to ensure a match
			if (fieldName === 'gage' && dataRowValue === 'Standard' && this.state.filters['gage'] && this.state.filters['gage'] === 'Alternate') {
				dataRowValue = 'Alternate'
			}

			// and, there is no "Inverted" type deck in the data source, they're identical the non inverted types so instead of doubling the amount
			// of data we have, we just alias Inverted to non, but we still need to show the user's selected values in the UI
			if (filterValue === '1.5CDR' || filterValue === '1.5FDR') {
				// add the "R" for "Inverted"
				dataRowValue += 'R'
			}

			// "No Uplift" is stored as an empty string in the database, but in the application, it's "No Uplift" so
			// convert the dataRowValue to match what is being searched for

			if (dependencyField === 'netUplift' && (dataRowValue === '' || dataRowValue === 'false')) {
				dataRowValue = 'NO_NETUPLIFT'
			}

			// In data, these two values are 145 and 100, but in filters, they're NW (145) and LW (110)
			if (dependencyField === 'concreteUnitWeight') {
				dataRowValue = dataRowValue.toString().replace('145', 'NW (145)').replace('110', 'LW (110)')
			}

			if (filterValue && fieldsForFormType[dependencyField] && fieldsForFormType[dependencyField].compoundFields) {
				hasCompoundFields = true
				const constituentFields = Utils.queryStringToObject(filterValue)
				delete constituentFields.queryString

				for (let constituentField in constituentFields) {
					if (row.value[constituentField] != constituentFields[constituentField]) {
						compoundFieldsValid = false
					}
				}
			}

			//
			if (filterValue && fieldsForFormType[dependencyField] && fieldsForFormType[dependencyField].rangeLow && fieldsForFormType[dependencyField].rangeHigh) {
				hasRangeInputs = true
				let rangeLow = row.value[dependencyField + 'Low']
				let rangeHigh = row.value[dependencyField + 'High']

				let filterValueInches = filterValue

				// let filterValue = this.state.filters[fieldName];
				if (fieldsForFormType[dependencyField].units && fieldsForFormType[dependencyField].units === 'ft') {
					filterValueInches *= 12
				}

				rangeInputFieldValid = filterValueInches >= rangeLow && filterValueInches <= rangeHigh
			}
			// ADD THIS ITEM IF:
			// - there is no filter value set yet
			// - there is a filter value and it matches the data row
			// - or, this is the TOGGLE Gage field, where standard/alternate values are NEVER exclusive so just pass it unconditionally
			// - or, this is the TOGGLE Design Method field, where ASD/LRFD values are NEVER exclusive so just pass it unconditionally
			// - or, this is the TOGGLE Concrete Unity Weight field, where 145/110 values are NEVER exclusive so just pass it unconditionally
			// - or there is a filter value and it's a compound field and the constituent parts all match the row values
			// - or the dependency field value needed to be evaluated as a range, and the data row value is within that range

			// prettier-ignore
			if (	
				!filterValue || 
				(filterValue && filterValue === dataRowValue) || 
				(dependencyField === 'gage' && (filterValue === 'Alternate' || filterValue === 'Standard')) || 
				(dependencyField === 'designMethod' && (filterValue === 'ASD' || filterValue === 'LRFD')) || 
				(dependencyField === 'concreteUnitWeight' && (filterValue === 'NW (145)' || filterValue === 'LW (110)')) || 
				(hasCompoundFields && filterValue && compoundFieldsValid) || 
				(hasRangeInputs && filterValue && rangeInputFieldValid)) {
					// if( fieldName === 'supportFasteners') {
					// 	console.log(fieldName, optionValue)
					// 	console.log(1, !filterValue)
					// 	console.log(2, filterValue && filterValue === dataRowValue)
					// 	console.log(3, dependencyField === 'gage' && (filterValue === 'Alternate' || filterValue === 'Standard'))
					// 	console.log(4, dependencyField === 'designMethod' && (filterValue === 'ASD' || filterValue === 'LRFD'))
					// 	console.log(5, dependencyField === 'concreteUnitWeight' && (filterValue === 'NW (145)' || filterValue === 'LW (110)'))
					// 	console.log(6, hasCompoundFields && filterValue && compoundFieldsValid)
					// 	console.log(7, hasRangeInputs && filterValue && rangeInputFieldValid)
					// }

					// build compound string labels for relevant fields
					if (fieldsForFormType[fieldName] && fieldsForFormType[fieldName].compoundFields) {
						let compoundFieldText = ''
						let compoundFieldValue = [fieldName + '=' + optionValue]

						// iterate the compound fields for this field
						fieldsForFormType[fieldName].compoundFields.forEach((compoundFieldName) => {
							const inchMark = (compoundFieldName === 'support4' || compoundFieldName === 'support3') && !isNaN(row.value[compoundFieldName]) ? '"' : ''

							// get a handle on the value
							const compoundFieldNameValue = row.value[compoundFieldName]

							// push value into array before we begin to modify the text value for prettyness
							compoundFieldValue.push(compoundFieldName + '=' + compoundFieldNameValue)

							// when the value is not an empty string, add an optional space for breathing room and
							// when appropriate, add an inch mark
							if (compoundFieldNameValue !== '') {
								const space = compoundFieldText === '' ? '' : ' '
								compoundFieldText += space + compoundFieldNameValue + inchMark
							}
						})

						if (compoundFieldText !== '') {
							optionText += ' (' + compoundFieldText + ')'
							optionValue = compoundFieldValue.join('&')
						}
					}

					// convert the actual value to the alias value for deckType, as determiend in config (eg, 1.0FD = 1.0FD, 1.0FDV)
					if (fieldName === 'deckType') {
						if (fieldsForFormType[fieldName].options[optionValue]) {
							optionText = fieldsForFormType[fieldName].options[optionValue].text
						}
					}

					// YES, gross.

					// Don't add 50 or 80 when the decktype is in range of provided array
					if (fieldName === 'minYieldStressForGrade' && this.state.filters.deckType && ['2.0VLSES', '2.0VLSESA', '2.0VSES', '2.0VSESA', '3.5VLS', '3.5VLSA'].includes(this.state.filters.deckType) && [50, 80].includes(optionValue)) {
						optionText = optionValue = null
					}

					// Don't add 40 when the decktype is NW32
					if (fieldName === 'minYieldStressForGrade' && this.state.filters.deckType && this.state.filters.deckType === 'NW32' && optionValue === 40) {
						optionText = optionValue = null
					}

					matches.push(row)

					if (optionValue) {
						options[optionValue] = {
							text: optionText,
							value: optionValue,
						}
					}
				}
		})

		return {
			matches: matches,
			options: options,
		}
	}

	getDeckTypeData(productType) {
		const endpoint = this.baseUrl + '/get-data.php?method=productType&type=' + productType
		const deferred = $.Deferred()

		console.log('endoiunbt', endpoint);
		$.getJSON(endpoint, (response) => {
			deferred.resolve(response)
		})

		return deferred
	}

	getDiaphragmDesignDeckTypeData(filters) {
		const deckType = filters.deckType || null
		const mysfg = filters.minYieldStressForGrade || null
		//const mysfg = null;
		//const endpoint = this.baseUrl + "/get-data.php?method=diaphragmDesignDetail&deckType=" + deckType + "&mysfg=" + mysfg;
		const endpoint = this.baseUrl + '/get-data.php?method=diaphragmDesignDetail&deckType=' + deckType

		const deferred = $.Deferred()

		$.getJSON(endpoint, (response) => {
			// when/if filters are set, reduce this data so it reflects only possible selections
			//const data = self.reduceDiaphragmDesignDeckTypeData(response.rows);

			deferred.resolve(response.rows)
		})

		return deferred
	}

	getDefaultFilters(formMode) {
		const fieldConfig = formMode === this.config.formMode.LOAD_TABLES ? this.formConfig.fields : this.formConfig.diaphragmDesignFieldConfig

		const filters = {}

		for (const fieldName in fieldConfig) {
			if (fieldConfig[fieldName].defaultValue) {
				let filterFieldValue = fieldConfig[fieldName].defaultValue

				// some fields have been modified in Config.js because NewMill would like to see more presentable values in the UI
				// but those values don't exist in their XLS, so they also don't exist in Couch; instead, they're post-modified
				// strip those appendages so that filtration continues to work
				if (fieldConfig[fieldName].stripFromFieldValue) {
					filterFieldValue = filterFieldValue.replace(fieldConfig[fieldName].stripFromFieldValue, '')
				}

				filters[fieldName] = filterFieldValue
			}
		}

		return filters
	}

	collapseDeckDetail() {
		this.setState({
			activeItemId: null,
		})
	}

	selectDeck(deckId, minYieldStressForGrade) {
		if (!deckId) {
			this.collapseDeckDetail()
			return
		}

		const config = new Config()
		const baseUrl = config.getBaseUrl()

		const productType = config.getDeckTypeShortName(this.props.productType)

		const isDiaphragmDesign = this.state.formMode === this.config.formMode.DIAPHRAGM_DESIGN

		//const deckType = this.state.loadTableMatches[deckId].deckType.value;
		//const deckType = this.state.resultRows[deckId].deckType.value;
		//const dataUrl = baseUrl + "/get-data.php?method=diaphragmDesignDetail&deckType=" + deckType + "&mysfg=" + minYieldStressForGrade;

		const documentId = this.state.formMode === this.config.formMode.DIAPHRAGM_DESIGN ? deckId : this.getDocumentId(this.state.resultRows[deckId])

		let htmlUrl = baseUrl + '/create-html.php?type=' + productType + '&documentId=' + documentId + '&isDiaphragmDesign=' + isDiaphragmDesign

		// because 1.5FDI doesn't exist in the database - it's just a 1.5FD. But we still want the PDF generator
		// to know what the user selected
		if (this.state.filters && this.state.filters.deckType) {
			htmlUrl += '&deckTypeAlias=' + this.state.filters.deckType
		}

		$.get(htmlUrl).then(
			function (response) {
				this.setState({
					activeItemId: deckId,
					activeDeckHtml: response,
				})

				// return response
			}.bind(this)
		)
	}

	getDeckData(deckId) {
		//return this.state.loadTableMatches[deckId];
		return this.state.resultRows[deckId]
	}

	getDocumentId(row, gageValue = null, designMethodValue = null, concreteUnitWeightValue = null) {
		let segments = []

		// This method can be called directly for a documentId
		// or, it can be called because a meta filter changed, while this documentId was visible, in which case,
		// we'll only have the activeItemId on hand, so a new documentId needs to be formed.
		// When that is the case, the documentId will have been used to get the row, then the primaryFilter values
		// will be passed it as substitutes

		let gage = gageValue || this.state.filters.gage

		if (!gage && row && row.gage) {
			gage = row.gage.value
			//const activeDeck = this.state.resultRows[this.state.activeItemId];
			//gage = activeDeck.gage.value;
		}

		const designMethod = designMethodValue || this.state.filters.designMethod
		let concreteUnitWeight = null

		switch (this.props.productType) {
			case 'Form_Deck':
				// concrete unit weight will always be 145 in the document data - so instead of reading it from the document id
				// defer to the caller, if an override is provided
				concreteUnitWeight = concreteUnitWeightValue || row.concreteUnitWeight.value

				// ...unless a  filter value exists
				if (this.state.filters.concreteUnitWeight) {
					concreteUnitWeight = concreteUnitWeight = this.state.filters.concreteUnitWeight.match(/\d+/)
				}

				segments = [this.props.productType, row.deckType.value, gage, row.minYieldStressForGrade.value, designMethod, row.uniformConstructionLiveLoad.value, concreteUnitWeight, row.concreteStrength.value, row.totalSlabDepths.keyValue, row.unshoredSpans.keyValue]
				break

			case 'Roof_Deck':
				// retrieve the toggled value of Default Construction Loads because now it's a meta filter
				// if it doesn't exist, then just use the value associated with the row

				// this used to branch between SDI RD Std and IBC but now we're just disabling this as a choice
				// because SDI says it produces unreliable choices

				const defaultConstructionLoads = 'SDI RD Std'

				//let defaultConstructionLoads = null;
				//if( this.state.filters && this.state.filters.defaultConstructionLoads )
				//{
				//	defaultConstructionLoads = this.state.filters.defaultConstructionLoads
				//}
				//else
				//{
				//	defaultConstructionLoads = row.defaultConstructionLoads.value;
				//}
				//
				//defaultConstructionLoads = defaultConstructionLoads.replace(' (Ind Std)' , '');
				segments = [
					this.props.productType,
					row.deckType.value,
					gage,
					row.minYieldStressForGrade.value,
					designMethod,
					defaultConstructionLoads,
					//row.defaultConstructionLoads.value
				]

				break

			case 'Composite_Deck':
				// concrete unit weight will always be 145 in the document data - so instead of reading it from the document id
				// defer to the caller, if an override is provided
				concreteUnitWeight = concreteUnitWeightValue || row.concreteUnitWeight.value

				// ...unless a  filter value exists
				if (this.state.filters.concreteUnitWeight) {
					concreteUnitWeight = concreteUnitWeight = this.state.filters.concreteUnitWeight.match(/\d+/)
				}

				segments = [this.props.productType, row.deckType.value, gage, row.minYieldStressForGrade.value, designMethod, row.uniformConstructionLiveLoad.value, concreteUnitWeight, row.concreteStrength.value, row.totalSlabDepths.keyValue, row.unshoredSpans.keyValue]
				break

			default:
				segments = []
		}

		return segments.join('_')
	}

	// iterate through all the filters in state until the currently requested filter is identified; anything after
	// that should be cleared out
	clearForwardFilters(currentFilter) {
		let isForward = false
		const filters = {}

		const fieldOrderMap = this.formConfig[this.state.formMode + '_FieldOrderMap']

		//this.formConfig.fieldOrderMap.forEach( filter =>
		fieldOrderMap.forEach((filter) => {
			// if we're NOT already across the line, add the filter
			if (!isForward || (this.formConfig.fields[filter] && this.formConfig.fields[filter].dontClear)) {
				filters[filter] = this.state.filters[filter]
			}

			// if this is the current filter, indicate we're crossing the line so forward filters will be cleared out
			if (filter === currentFilter) {
				isForward = true
			}
		})

		this.setState({
			filters: filters,
		})

		this.forceUpdate()

		return filters
	}

	applyFilter(name, value) {
		const productType = this.productTypeMap[this.props.productType]

		// A field spec object representing either Load Tables or Diaphragm Design
		const fieldConfig = this.state.fieldConfig

		// a Meta Filter is Design Method, Gage or Concrete Weight.
		const isMetaFilter = fieldConfig[name] && fieldConfig[name].metaFilter

		// present state of filters
		//const filters = (isMetaFilter && isDiaphragmDesign )
		//	? this.resetFilters(this.state.formMode)
		//	: this.state.filters;

		//let filters = this.state.filters;
		let filters = this.clearForwardFilters(name)

		let activeItemId = null

		// toggles don't register activeItemId change fast enough - force a state change early
		//this.setState({
		//	'activeItemId': activeItemId
		//});
		//this.forceUpdate();

		if (fieldConfig[name].stripFromFieldValue) {
			value = value.replace(fieldConfig[name].stripFromFieldValue, '')
		}

		// the value "No Concrete" is manufactured by this script so that it's value is not an empty string
		// but if that is the value of the current filter, then convert it back to an empty string so it matches
		// when comparing to API data
		// CM: actually, I'm seeing this value in data as "No Concrete" now, so disabling this
		if (value === 'No Concrete') {
			//value = '';
		}

		//const filterOnNullValue = fieldConfig[name].hasOwnProperty('filterOnNullValue') && fieldConfig[name]['filterOnNullValue'];

		// there are no CDR and FDR deck types in returned data; they're always CD and FD so convert those
		//value = value.replace('1.5FDR', '1.5FD').replace('1.5CDR', '1.5CD');

		// add to filters
		// if a null/empty value is provided, delete it

		value ? (filters[name] = value) : delete filters[name]

		if (fieldConfig[name].hasOwnProperty('dontFilter')) {
			//return true;
		}

		const renderResults = !fieldConfig[name].hasOwnProperty('dontFilter') && this.hasFiltersApplied(filters)

		// filter for Diaphragm Design
		// require deckType, mysfg and gage before returning result rows
		// deckType and MYSFG are requirements. Gage is just to reduce the volume, otherwise we're building out 57,000 + rows
		//if( this.state.formMode === this.config.formMode.DIAPHRAGM_DESIGN && filters.deckType && filters.minYieldStressForGrade )
		if (this.state.formMode === this.config.formMode.DIAPHRAGM_DESIGN) {
			// modify the filters collection if any filter was changed backwards of this one
			//filters = this.clearForwardFilters(name);

			// start spinner
			this.setState({
				filters: filters,
				isFiltering: true,
				activeItemId: activeItemId,
			})

			// this is used to throttle rendering results until all required filters have been selected
			// by default, assume it's ok to render
			let showResults = true

			if (this.formConfig[this.state.formMode + '_RequiredForResults']) {
				const requiredFields = this.formConfig[this.state.formMode + '_RequiredForResults']
				requiredFields.forEach((field) => {
					if (!filters.hasOwnProperty(field)) {
						showResults = false
					}
				})
			}

			showResults = renderResults

			this.forceUpdate()

			$.when(this.getDiaphragmDesignDeckTypeData(filters)).then(
				function (response) {
					const results = this.prepData(response, this.config.formMode.DIAPHRAGM_DESIGN)

					this.setState({
						count: results.count,
						filteredResultCount: results.filteredResultCount,
						matches: results.matches,
						resultRows: results.resultRows,
						resultRowsFieldConfig: results.resultRowsFieldConfig,

						// TODO
						// confirm these are required for DD
						//'isFiltered': this.hasFiltersApplied(),
						filters: filters,
						activeItemId: activeItemId,

						isFiltered: true,
						isFiltering: false, // stop spinner

						showResults: showResults,
					})
				}.bind(this)
			)
		}

		// filter for Load Tables
		else {
			// if a meta filter is selected while a deck detail panel is expanded, just identify the alternate
			// documentId and re-render the expanded state
			if (isMetaFilter && this.hasFiltersApplied() && this.state.activeItemId !== null && 'i want this to work like this again' === 1) {
				const row = this.state.resultRows[this.state.activeItemId]
				const activeDeck = this.state.resultRows[this.state.activeItemId]
				let gage = false

				if (filters.gage) {
					gage = filters.gage
				} else if (fieldConfig && fieldConfig.fields && fieldConfig.fields['gage']) {
					gage = fieldConfig.fields['gage'].defaultValue
				} else if (activeDeck && activeDeck.gage) {
					gage = activeDeck.gage.value
				}

				//let gage = filters.gage || fieldConfig.fields['gage'].defaultValue;

				let designMethod = null
				if (filters.designMethod) {
					designMethod = filters.designMethod
				} else {
					if (fieldConfig['designMethod'] && fieldConfig['designMethod'].defaultValue) {
						designMethod = fieldConfig['designMethod'].defaultValue
					}
				}

				let concreteUnitWeight = null
				if (filters.concreteUnitWeight) {
					concreteUnitWeight = filters.concreteUnitWeight.match(/\d+/)
				} else {
					if (fieldConfig['concreteUnitWeight']) {
						concreteUnitWeight = fieldConfig['concreteUnitWeight'].defaultValue.match(/\d+/)
					}
				}

				activeItemId = this.getDocumentId(row, gage, designMethod, concreteUnitWeight)

				if (activeItemId !== null) {
					//const deck = this.state.loadTableMatches[this.state.activeItemId];
					const deck = this.state.resultRows[this.state.activeItemId]
					this.selectDeck(this.state.activeItemId, deck.minYieldStressForGrade.value)
				}
			} else {
				$.when(this.getDeckTypeData(productType)).then(
					function (response) {
						const results = this.prepData(response.rows, this.config.formMode.LOAD_TABLES)

						this.setState({
							count: results.count,
							filteredResultCount: results.filteredResultCount,
							matches: results.matches,
							resultRows: results.resultRows,
							resultRowsFieldConfig: results.resultRowsFieldConfig,

							// TODO
							// confirm these are required for DD
							//'isFiltered': this.hasFiltersApplied(),
							isFiltered: true,
							filters: filters,
							activeItemId: activeItemId,
							isFiltering: false, // stop spinner

							//'showResults': true
							showResults: renderResults,
						})
					}.bind(this)
				)
			}

			/*this.setState({
				'isFiltered': this.hasFiltersApplied(),
				'filters': filters,
				'activeItemId': activeItemId
			});*/

			// commenting this out because i think the detail view needs to go away anytime
			// a filter change is made, right?
			//this.filterViewData();
		}
	}

	resetFilters(fieldConfig) {
		const filters = {}

		// create a filters object which consists only of Meta Filters
		for (const filter in fieldConfig) {
			if (fieldConfig[filter].metaFilter) {
				filters[filter] = filter
			}
		}

		this.setState({
			isFiltered: false,
			filters: filters,
		})

		return filters
		// update the view
		//this.filterViewData(true);
	}

	hasFiltersApplied(source) {
		let filtered = false
		const filters = source || this.state.filters

		//const fieldConfig = this.state.fieldConfig;
		const fieldConfig = this.state.formMode === this.config.formMode.LOAD_TABLES ? this.formConfig.fields : this.formConfig.diaphragmDesignFieldConfig

		for (const filter in filters) {
			if (fieldConfig[filter] && !fieldConfig[filter].metaFilter) {
				filtered = true
			}
		}

		//return true;
		return filtered
	}

	isFilteringClassName() {
		return this.state.isFiltering ? 'results-container results-container--isfiltering' : 'results-container'
	}

	updateDiaphragmDesignState(state) {
		this.setState({
			diaphragmDesignStateData: state,
		})
	}

	/*
	 * Switch between Load Tables and Diaphragm Design
	 * Any existing filters will be purged when toggling between tools
	 *
	 * */

	toggleFormMode(formMode) {
		// No data calls for this - its just an iframe
		if (formMode === 'long-span-load-tables') {
			this.setState({
				formMode,
				ready: true,
			})

			return
		}

		const productType = this.productTypeMap[this.props.productType]
		const fieldConfig = formMode === this.config.formMode.LOAD_TABLES ? this.formConfig.fields : this.formConfig.diaphragmDesignFieldConfig

		$.when(this.getDeckTypeData(productType))
			.then((response) => {
				const results = this.prepData(response.rows, formMode)

				const state = {
					matches: results.matches,

					// empty any existing filters
					filters: this.getDefaultFilters(formMode),

					// update the form mode
					formMode: formMode,

					// start from scratch on filter state
					isFiltered: false,

					// default to .fields, for Load Tables
					fieldConfig: fieldConfig,

					resultRows: results.resultRows,

					resultRowsFieldConfig: results.resultRowsFieldConfig,

					ready: 'ready',
				}

				this.setState(state)

				//this.filterViewData();

				document.body.classList.add('ready')
			})
			.catch((e) => {
				console.log('error')
				console.log(e)
			})
	}

	validateRowFields(row, fieldConfig) {
		let matched = true
		for (const fieldName in fieldConfig) {
			const fieldValue = row[fieldName]

			// a toggle will be a field for which there is always a match, like Design Method (ASD/LRFD); there is an
			// ASD AND LRFD instance of every deck. So, if the filter we're validating is "ASD" and the deck is "LRFD"
			// don't fail it because we'll never get an LRFD back in data;

			const isToggle = fieldConfig[fieldName].hasOwnProperty('isToggle')

			if (!this.validate(fieldName, fieldValue, row, fieldConfig) && !isToggle) {
				matched = false
			}
		}

		return matched
	}

	//validate(fieldName, fieldValue, documentData, formMode)
	validate(fieldName, fieldValue, documentData, fieldConfig) {
		// a field spec for either Load Tables or Diaphragm Design
		//const fieldConfig = this.state.fieldConfig;
		// const fieldConfig = (formMode === this.config.formMode.LOAD_TABLES)
		// 	? this.formConfig.fields
		// 	: this.formConfig.diaphragmDesignFieldConfig;

		// standard method
		let validationMethod = 'equality'

		// compound fields like sidelap fasteners and support fasteners
		if (fieldConfig[fieldName].compoundFields) validationMethod = 'compoundFields'

		// totalSlabDepth, etc high low values
		if (fieldConfig[fieldName].hasOwnProperty('rangeLow') && fieldConfig[fieldName].hasOwnProperty('rangeHigh')) validationMethod = 'range'

		// assume it doesn't match until determined otherwise
		let matched = false

		// don't validate a meta filter, unless it's gage
		//if( (fieldConfig[fieldName] && fieldConfig[fieldName].metaFilter && fieldName !== 'gage') || (fieldConfig[fieldName].hasOwnProperty('dontValidate')) )
		if (fieldConfig[fieldName].hasOwnProperty('dontValidate')) {
			return true
		}

		// LW and NW Concrete data is post processed to present more readable options to the user. The values in those
		// <options> are optimized for creating a reference to the individual documents: LW Concrete_2_Y, for instance
		// but that appendage needs to be removed when validating actual data

		if (fieldValue && fieldConfig[fieldName].stripFromFieldValue) {
			fieldValue = fieldValue.replace(fieldConfig[fieldName].stripFromFieldValue, '')
		}

		switch (validationMethod) {
			case 'compoundFields':
				matched = this.validateCompoundFields(fieldName, fieldValue, documentData, fieldConfig)
				break

			case 'range':
				matched = this.validateByRangeInput(fieldName, fieldValue, documentData)
				break

			default:
				matched = this.validateByEquality(fieldName, fieldValue)
				break
		}

		return matched
	}

	validateCompoundFields(fieldName, fieldValue, documentData, fieldConfig) {
		// TODO:
		// instead of switching on inputType, define a variable called validationMethod, which will be
		// - range
		// - compoundField
		// - default
		// when range or default, do as it is currently
		// when compoundField, run range/default in a loop to validate each one

		let matched = true

		if (this.state.filters[fieldName]) {
			const filterValues = Utils.queryStringToObject(this.state.filters[fieldName])

			// first thing, match the base field (like supportFasteners or sidelapFasteners)
			if (filterValues[fieldName] !== fieldValue) {
				matched = false
			}

			// if there wasn't a mismatch with the base field, try each of the compound fields
			else {
				this.state.fieldConfig[fieldName].compoundFields.forEach(function (compoundField) {
					if (filterValues[compoundField] != documentData[compoundField]) {
						matched = false
					}
					//documentValues[compoundField] = this.state.resultRows[documentId][compoundField];
				})
			}
		}

		return matched
	}

	validateByRangeInput(fieldName, fieldValue, documentData) {
		let matched = true

		if (this.state.filters[fieldName]) {
			let rangeLow = -1
			let rangeHigh = -1

			if (fieldValue.hasOwnProperty('ranges')) {
				rangeLow = fieldValue.ranges.rangeLow
				rangeHigh = fieldValue.ranges.rangeHigh
			}

			if (rangeLow === -1 && documentData[fieldName + 'High']) {
				rangeLow = documentData[fieldName + 'Low']
				rangeHigh = documentData[fieldName + 'High']
			}

			let filterValue = this.state.filters[fieldName]

			if (this.state.fieldConfig[fieldName].units && this.state.fieldConfig[fieldName].units === 'ft') {
				filterValue *= 12
			}

			matched = filterValue >= rangeLow && filterValue <= rangeHigh
		}

		return matched
	}

	validateByEquality(fieldName, fieldValueParam) {
		let matched = true

		if (this.state.filters[fieldName]) {
			let filterFieldValue = this.state.filters[fieldName]

			let fieldValue = fieldValueParam

			if (typeof fieldValue === 'object') {
				fieldValue = fieldValue.value
			}

			// at a minimum, netUplift can have an option whose valid value is an empty string
			// generally, an empty string is NOT a settable filter value so a substitute token is
			// provided for this field - when validating it, replace the token, if it exists
			// so that a comparison can be made against each product
			if (this.state.fieldConfig[fieldName].hasOwnProperty('replacePlaceholderValue')) {
				filterFieldValue = filterFieldValue.replace(this.state.fieldConfig[fieldName].replacePlaceholderValue, '')
			}

			// there will never be a 1.5CDR or 1.5FDR document returned - they're derivative versions of the base 1.5CD
			// and 1.5FD - so strip the "R" from the value
			if (fieldName === 'deckType') {
				filterFieldValue = filterFieldValue.replace('1.5FDR', '1.5FD').replace('1.5CDR', '1.5CD')
				//.replace('1.5FDI', '1.5FD');
			}

			if (filterFieldValue != fieldValue && filterFieldValue !== 'all') {
				matched = false
			}
		}

		return matched
	}

	filterViewData(reset = false) {
		const matches = []
		let count = 0

		for (const [documentId] of Object.entries(this.state.resultRows)) {
			const documentData = this.state.resultRows[documentId]

			// match it by default
			let matched = true

			if (reset) {
				matches.push(documentData)
				count += 1
			} else {
				if (matched) {
					matches.push(documentData)
					//resultRows[documentId] = documentData;
					count += 1
				}
			}
		}

		this.setState({
			matches: matches,

			// disabling resultRows state change
			// this method only returns a matching set; shouldn't reduce the source that can/will be queried for further sorting
			//'resultRows': resultRows,

			filteredResultCount: count,
			ready: 'ready',
		})
	}

	getFilterFormWrapperClass() {
		let className = 'filter-form-wrapper filter-form-wrapper--' + this.state.productType

		if (this.state.ready === 'ready' || this.state.formMode === 'long-span-load-tables') {
			className += ' filter-form-wrapper--ready'
		}

		if (this.state.isFiltering) {
			className += ' filter-form-wrapper--is-filtering'
		}

		return className
	}

	componentDidMount() {
		// initialize app in Load Tables mode
		this.toggleFormMode(this.config.formMode.LOAD_TABLES)
	}

	clearFilters() {
		this.setState({
			filters: {},
		})

		this.toggleFormMode(this.state.formMode)
	}

	render() {
		return (
			<React.Fragment>
				<div className="loading-spinner">
					<img
						src={require('./images/tail-spin.svg')}
						alt=""
					/>
				</div>

				<div className={this.getFilterFormWrapperClass()}>
					<FilterHeader
						filters={this.state.filters}
						resultRows={this.state.resultRows}
						resultRowsFieldConfig={this.state.resultRowsFieldConfig} // result rows
						filterViewDataFn={this.filterViewData}
						applyFilterFn={this.applyFilter}
						productType={this.productTypeMap[this.props.productType]}
						formMode={this.state.formMode}
						toggleFormMode={this.toggleFormMode}
						clearFilters={this.clearFilters}
						fieldConfig={this.state.fieldConfig} // the organized field spec for load tables for this deck type
					/>

					{this.state.formMode === 'long-span-load-tables' && (
						<div className="u-site-width">
							<div className="u-margin-buffer">
								<iframe
									src="https://apps.newmill.com/Designer/a/deckCalc_v310_forWA_R1"
									style={{ height: '500px', width: '100%' }}
									border="0"
									frameBorder="0"></iframe>
							</div>
						</div>
					)}
					<div className={this.state.isFiltering ? 'results-container results-container--isfiltering' : 'results-container'}>
						{this.state.showResults &&
							this.state.matches.map((row, index) => (
								<FilterResultRows
									key={'filterResultRows-' + index}
									fieldConfig={this.state.fieldConfig}
									resultRows={this.state.resultRows}
									resultRowsFieldConfig={this.state.resultRowsFieldConfig} // Load Table matched documents for display
									formMode={this.state.formMode}
									isFiltered={this.state.isFiltered}
									isFiltering={this.state.isFiltering}
									productType={this.props.productType}
									productTypeShortName={this.productTypeMap[this.props.productType]}
									selectDeckFn={this.selectDeck}
									getDeckDataFn={this.getDeckData}
									getDocumentIdFn={this.getDocumentId}
									activeItemId={this.state.activeItemId}
									activeDeckHtml={this.state.activeDeckHtml}
									row={row}
									gage={this.state.filters.gage}
									filters={this.state.filters}
								/>
							))}

						{this.state.isFiltered && this.state.filteredResultCount === 0 && <div className="no-results">No results match your design specifications</div>}
					</div>
				</div>

				{this.maintenanceMode && (
					<div className="u-site-width">
						<div className="u-margin-buffer">
							<div className="u-content-width">
								<p>
									<br />
								</p>
								<h3>Maintenance Advisory</h3>
								<p>We will be performing maintenance updates on Tuesday, August 17th from 8:00am CST - 9:00pm CST.</p>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}
}

export default Filter
