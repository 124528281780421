import React, { Component } from 'react'
import $ from 'jquery'

class PageWrapper extends Component {
	constructor(props) {
		super()

		this.state = {
			activeTab: props.activeTab,
			hasAcceptedTerms: this.hasRespondedToTerms(),
			maintenanceMode: false,
		}

		this.getTabClassName = this.getTabClassName.bind(this)
		this.burgerClick = this.burgerClick.bind(this)

		if ('ontouchstart' in document.documentElement) {
			document.body.classList.add('touch-device')
		}
	}

	getTabClassName(tab) {
		let className = 'tab'

		if (tab === this.state.activeTab) {
			className += ' tab--active'
		}
		return className
	}

	burgerClick() {
		if (document.body.classList.contains('burger-expanded')) {
			document.body.classList.remove('burger-expanded')
		} else {
			document.body.classList.add('burger-expanded')
		}
	}

	acceptTerms(accept) {
		const now = new Date().getTime() // Get current timestamp in milliseconds
		window.localStorage.setItem('hasAcceptedTerms', JSON.stringify({ accepted: true, timestamp: now }))
		this.setState({
			hasAcceptedTerms: true,
		})
	}

	hasRespondedToTerms() {
		const item = window.localStorage.getItem('hasAcceptedTerms')
		if (item) {
			const { accepted, timestamp } = JSON.parse(item)
			const oneYearInMillis = 365 * 24 * 60 * 60 * 1000 // One year in milliseconds
			const now = new Date().getTime()

			if (accepted && now - timestamp <= oneYearInMillis) {
				return true
			} else {
				window.localStorage.removeItem('hasAcceptedTerms') // Remove the item if it's older than a year or not accepted
				return false
			}
		}

		return false
	}

	getMaintenanceMode() {
		return (
			<div className="u-site-width">
				<div className="u-margin-buffer">
					<div className="u-content-width">
						<h2 className="u-capitalize">Maintenance</h2>

						<p>Site is under maintance but will be restored shortly. Thank you for your patience.</p>
					</div>
				</div>
			</div>
		)
	}

	getLicenseAgreement() {
		return (
			<div className="u-site-width">
				<div className="u-margin-buffer">
					<div className="u-content-width">
						<h2 className="u-capitalize">Design Tool License Agreement</h2>

						<table
							className="fullColumn agree"
							cellSpacing="0">
							<tbody>
								<tr>
									<td
										colSpan="2"
										className="blue">
										<p>
											PLEASE READ OUR{' '}
											<a
												href="https://www.newmill.com/design-tool-license-agreement.html"
												target="_blank"
												rel="noreferrer noopener"
												id="license-link">
												DESIGN TOOL LICENSE AGREEMENT
											</a>{' '}
											("LICENSE AGREEMENT") BEFORE ACCESSING OR USING THE DESIGN TOOL APPLICATION. ACCESSING THE DESIGN TOOL APPLICATION INDICATES YOUR ACCEPTANCE OF THE FOLLOWING TERMS AND CONDITIONS. IF YOU DO NOT ACCEPT THE TERMS AND CONDITIONS OF THIS LICENSE AGREEMENT, YOU MUST NOT ACCESS OR USE THIS DESIGN TOOL APPLICATION. BY AGREEING TO THE TERMS AND CONDITIONS A COOKIE WILL BE PLACED ON YOUR BROWSER.{' '}
											<a
												href="../../resources/website-privacy-policy.html"
												target="_blank"
												rel="noopener noreferrer">
												PRIVACY POLICY
											</a>
											.
										</p>
									</td>
								</tr>
								<tr>
									<td className="grey small">
										<input
											onClick={() => {
												this.acceptTerms(true)
											}}
											type="checkbox"
											name="checkbox"
											id="agree"
											className="terms"
										/>
										<label
											onClick={() => {
												this.acceptTerms(true)
											}}
											htmlFor="agree"
											id="agree-label"></label>
									</td>
									<td className="grey large">
										<p>Agree</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}

	// appendScript(src) {
	// 	const script = document.createElement('script')
	// 	script.src = src
	// 	script.async = true
	// 	document.body.appendChild(script)
	// }

	componentDidMount() {
		// if (this.state.acceptedTracking) {
		// 	this.appendScript('https://tools.newmill.com/public/acton.js')
		// }
	}

	render() {
		return (
			<React.Fragment>
				<header
					id="masthead"
					className="masthead">
					<div className="u-site-width">
						<div className="u-margin-buffer">
							<div className="u-content-width">
								<div className="masthead__top">
									<div className="masthead__logo">
										<a href="/">
											<img
												src="/images/newmill-horizontal.svg"
												width="335"
												height="42"
												alt="New Millennium"
											/>
										</a>
									</div>

									<div className="tabs">
										<div className="tab tab--compact">
											<a
												href="https://www.newmill.com"
												target="_blank"
												rel="noopener noreferrer">
												newmill.com
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={'masthead__banner masthead__banner--' + this.props.banner}>
						<div className="u-site-width">
							<div className="u-margin-buffer">
								<div className="u-content-width u-relative">
									<button
										className="burger"
										id="burger"
										onClick={this.burgerClick}></button>
									<nav className="global-nav global-nav--desktop">
										<ul>
											<li>
												<a href="/design-tools/">Design Tools</a>
											</li>
											<li>
												<a href="/deck-tools/">Deck Tools</a>
											</li>
											<li>
												<a
													href="https://www.newmill.com/design-tools/tools/standard-joist-tools.html"
													target="_blank"
													rel="noreferrer noopener">
													Standard Joist Tool
												</a>
											</li>
											<li>
												<a
													href="https://www.newmill.com/design-tools/tools/special-profile-tools.html"
													target="_blank"
													rel="noreferrer noopener">
													Special Profile Tool
												</a>
											</li>
											<li>
												<a
													href="https://www.newmill.com/design-tools/tools/girders-tool.html"
													target="_blank"
													rel="noreferrer noopener">
													Joist Girder Tool
												</a>
											</li>
										</ul>
									</nav>

									<div
										className="section-title u-h1"
										key="page-title">
										{this.props.pageTitle}
									</div>
								</div>

								{this.props.showTabs && (
									<nav className="masthead__tab-strip">
										<ul className="tabs tabs--bordered">
											<li className={this.getTabClassName('roof')}>
												<a href="/deck-tools/roof-deck/">Roof Deck</a>
											</li>
											<li className={this.getTabClassName('composite')}>
												<a href="/deck-tools/composite-deck/">Composite Deck</a>
											</li>
											<li className={this.getTabClassName('form')}>
												<a href="/deck-tools/form-deck/">Form Deck</a>
											</li>
										</ul>
									</nav>
								)}
							</div>
						</div>
						<nav className="global-nav global-nav--mobile">
							<ul>
								<li>
									<a href="/design-tools/">Design Tools</a>
								</li>
								<li>
									<a href="/deck-tools/">Deck Tools</a>
								</li>
							</ul>
						</nav>
					</div>
				</header>

				<div className="main-content">{this.state.maintenanceMode ? this.getMaintenanceMode() : this.state.hasAcceptedTerms ? this.props.children : this.getLicenseAgreement()}</div>

				<footer className="footer">
					<div className="u-site-width">
						<div className="u-margin-buffer">
							<div className="tagline">Building a better steel experience</div>
						</div>
					</div>

					<div className="color-band">
						<div className="u-site-width">
							<div className="u-margin-buffer">
								<div className="footer-columns">
									<div className="footer-columns__column">
										<address>
											<p>
												Headquarters
												<br />
												1690 Broadway, Bldg. 19, Ste. 160
												<br />
												Fort Wayne, IN 46802
												<br />
												<a
													href="tel:+12603218080"
													aria-label="260.321.8080."
													className="phone">
													Phone: 260.321.8080
												</a>
											</p>
										</address>

										<p className="copyright">&copy; {new Date().getFullYear()} New Millennium Building Systems, LLC All rights reserved.</p>
									</div>

									<div className="footer-columns__column">
										<div className="privacy-policy">
											<a
												href="https://www.newmill.com/design-tools/design-tool-license-agreement.html"
												target="_blank"
												rel="noreferrer noopener">
												Design Tool License Agreement
											</a>
											
											<a
												href="https://www.newmill.com/resources/website-privacy-policy.html"
												target="_blank"
												rel="noreferrer noopener">
												Privacy Policy
											</a>
											<a
												href="https://www.newmill.com/resources/website-accessibility-statement.html"
												target="_blank"
												rel="noreferrer noopener">
												Website Accessibility Statement
											</a>
										</div>
										<div className="footer-logo">
											<a
												href="https://www.steeldynamics.com/"
												target="_blank"
												rel="noopener noreferrer">
												<img
													src="/images/SDI-Steel-Dynamics-grey-01.svg"
													alt="Steel Dynamics, Inc"
												/>
											</a>
										</div>
										New Millennium Building Systems is a wholly owned subsidiary of Steel Dynamics, Inc.
									</div>
								</div>

								<div className="cookie-consent-wrapper">
									<button
										id="ot-sdk-btn"
										className="ot-sdk-show-settings">
										Cookie Settings
									</button>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</React.Fragment>
		)
	}
}

export default PageWrapper
