import React, { Component } from 'react';
import $ from 'jquery';

class FilterRangeInput extends Component
{

	constructor(props)
	{
		super();

		this.handleChange = this.handleChange.bind(this);

		const steps = [];
		const rangeLow = props.resultRowsFieldConfig[props.field].rangeLow;
		const rangeHigh = props.resultRowsFieldConfig[props.field].rangeHigh;

		const step = (rangeHigh - rangeLow) / 8;

		for(let i = 0; i<=8; i++)
		{
			steps.push(rangeLow + (i * step));
		}

		this.state = {
			'min': rangeLow,
			'max': rangeHigh,
			'step': step,
			'steps': steps
		};
	}

	componentDidMount()
	{
		//const rangeName = this.props.field;
		const ftName = this.props.field + '-ft-value';
		const inName = this.props.field + '-in-value';

		//this.$rangeSlideInput = $('input[name="' + this.props.field +'"]');
		this.$ftValueInput = $('input[name="' + ftName +'"]');
		this.$inValueInput = $('input[name="' + inName +'"]');
	}

	handleChange(e)
	{
		if( typeof this.props.applyFilterFn === 'function' )
		{
			const fieldName = e.target.getAttribute('name').replace('-ft-value', '').replace('-in-value', '');

			const ftInches = (parseInt(this.$ftValueInput.val(), 10) || 0) * 12;
			const inches = parseInt(this.$inValueInput.val(), 10) || 0;

			const totalInches = ftInches + inches;

			// need to convert to foot-inches instead of just passing in the ft or the in
			this.props.applyFilterFn(fieldName, totalInches);
		}
	}

	render()
	{
		return (
			<React.Fragment>
				<input placeholder="ft" className="u-input-small data-row__ft" type="text" key={this.props.field + '-ft-value'} name={this.props.field + '-ft-value'} id={this.props.field + '-ft-value'} onKeyUp={ this.handleChange } />
				<input placeholder="in" className="u-input-small data-row__in" type="text" key={this.props.field + '-in-value'} name={this.props.field + '-in-value'} id={this.props.field + '-in-value'} onKeyUp={ this.handleChange } />
				<div className="cell__range u-text-small">
					{this.state.min}" - {this.state.max}"
				</div>
			</React.Fragment>
		)
	}
}

export default FilterRangeInput;