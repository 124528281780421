import React, { Component } from 'react'

class FilterSelect extends Component {
	constructor(props) {
		super()

		this.handleChange = this.handleChange.bind(this)
		this.setDisabledState = this.setDisabledState.bind(this)
		this.shouldBeDisabled = this.shouldBeDisabled.bind(this)
		this.defaultOptionSelected = this.defaultOptionSelected.bind(this)
		this.focusHandler = this.focusHandler.bind(this)
		this.blurHandler = this.blurHandler.bind(this)

		this.state = {
			className: 'select',
			disabled: false,
			options: props.options,
		}
	}

	componentDidUpdate() {
		this.setDisabledState()
	}

	componentDidMount() {
		this.setDisabledState()
	}

	blurHandler(e) {
		this.setState({
			className: 'select',
		})
	}

	focusHandler(e) {
		this.setState({
			className: 'select hover',
		})
	}

	handleChange(e) {
		if (typeof this.props.applyFilterFn === 'function') {
			this.props.applyFilterFn(e.target.getAttribute('name'), e.target.value)
		}
	}

	shouldBeDisabled() {
		let shouldBeDisabled = false
		for (const field in this.props.fieldConfig) {
			const filterField = this.props.fieldConfig[field]

			if (filterField.requiredMetaField && !this.props.filters[field]) {
				shouldBeDisabled = true
			}
		}
		return shouldBeDisabled
	}

	// I hated this
	setDisabledState() {
		// when a field has a dependency
		if (this.props.filterFields[this.props.field].primaryFilter) {
			// get the current state
			const currentDisabledState = this.state.disabled

			// this field should be disabled when the dependent field has no entry in the current filters object
			//const shouldBeDisabled = ! this.props.filters.hasOwnProperty(dependentField);
			const shouldBeDisabled = this.shouldBeDisabled()

			//console.log(currentDisabledState, shouldBeDisabled);

			//console.log('shouldBeDisabled', shouldBeDisabled, currentDisabledState, dependentField, this.props.filters);
			// only change state when the current state doesn't match the new state
			if (currentDisabledState !== shouldBeDisabled) {
				//console.log('set to disabled');
				this.setState({
					disabled: shouldBeDisabled,
				})
			}
		}
	}

	defaultOptionSelected() {
		const isDisabled = this.props.disabledState
		const filterExists = this.props.filters && this.props.filters[this.props.field]

		return isDisabled || !filterExists ? '' : this.props.filters[this.props.field]

		//if( isDisabled || ! filterExists ) return "";
		//if(  this.props.disabledState || typeof this.state.filters[this.props.field] !== 'undefined') return "";
	}

	render() {
		// if the order in which these options is rendered is important, then a map will have been
		// defined, so iterate that list
		// otherwise, just iterate the options in the order in which they are presented
		const optionsToIterate = this.props.fieldConfig[this.props.field].optionsOrderMap ? this.props.fieldConfig[this.props.field].optionsOrderMap : Object.keys(this.props.options)

		return (
			<div className={this.state.className}>
				<select
					key={'select-' + this.props.field}
					name={this.props.field}
					onFocus={this.focusHandler}
					onBlur={this.blurHandler}
					onChange={this.handleChange}
					disabled={this.state.disabled}
					value={this.defaultOptionSelected()}>
					<option value="">Select</option>

					{optionsToIterate.map(
						(option) =>
							this.props.options[option] &&
							this.props.options[option].hasOwnProperty('value') && (
								<option
									key={'select-option-' + this.props.field + '-' + this.props.options[option].value}
									value={this.props.options[option].value}
									dangerouslySetInnerHTML={{ __html: this.props.options[option].text }}></option>
							)
					)}
				</select>
			</div>
		)
	}
}

export default FilterSelect
