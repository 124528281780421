import React, { Component } from 'react'
//import FilterSelect from './FilterSelect.js';
//import Utils from './Utils.js';
//import $ from 'jquery';
//import Config from './Config.js';

class DeckDetailPanel extends Component {
	constructor(props) {
		super()

		const deckData = props.getDeckDataFn(props.itemId)

		this.state = {
			gage: props.gage,
			filters: {},
			itemId: props.itemId,
			diaphragmDesignFields: {},
			visiblePanel: props.tabToDisplay,
			deckType: deckData.deckType.value,
			matchingDocuments: {},
		}

		// obsolete?
		this.handleStateChange = this.handleStateChange.bind(this)

		this.getPanelClass = this.getPanelClass.bind(this)
		this.getTabClass = this.getTabClass.bind(this)
		this.showPanel = this.showPanel.bind(this)
		this.collapseDetail = this.collapseDetail.bind(this)
		this.applyFilter = this.applyFilter.bind(this)
		//this.getDisabledState = this.getDisabledState.bind(this);
		this.getDiaphragmDesignDetail = this.getDiaphragmDesignDetail.bind(this)
	}

	// obsolete?
	handleStateChange(state) {
		this.props.updateDiaphragmDesignStateFn(state)
	}

	getPanelClass(panel) {
		return panel === this.state.visiblePanel ? 'panel panel--visible' : 'panel'
	}

	getTabClass(panel) {
		return panel === this.state.visiblePanel ? 'tab tab--active' : 'tab'
	}

	showPanel(panel) {
		this.setState({
			visiblePanel: panel,
		})
	}

	collapseDetail(e) {
		e.preventDefault()
		this.props.selectDeckFn(null)
	}

	// set filter state
	applyFilter(filterName, filterValue) {
		const filters = this.state.filters

		// if this value is numeric, convert it to an actual number because the comparisons which will be operated on it
		// will be strict
		if (!isNaN(filterValue)) {
			filterValue = parseFloat(filterValue)
		}

		filters[filterName] = filterValue

		// if this filter value is being unset, unset a filter value from this fields
		// dependency, when/if one exists
		// when a dependency exists, applyFilter will get called again, as each filter field matches with a related
		// dependency
		if (!filterValue) {
			this.emptyDependentFilterValue(filterName)
		}

		this.setState(filters)
	}

	// given a filter name as a subject, determine whether another field relies on it
	// when if it does, unset that filter value by invoking applyFilter
	// this process will cascade as long as there are fields with dependencies in this chain
	emptyDependentFilterValue(filter) {
		// find this filter in the diaphragmDesignFields
		for (const filterName in this.props.diaphragmDesignFields) {
			// this filter instance
			const filterInstance = this.props.diaphragmDesignFields[filterName]

			// if this filter instance depends upon the filter which was passed in,
			// unset its value
			if (filterInstance.dependsOn && filterInstance.dependsOn === filter) {
				this.applyFilter(filterName, '')
			}
		}
	}

	/*getDisabledState(field)
	{
		let disabled = false;

		if( this.props.diaphragmDesignFields[field].dependsOn )
		{
			const dependentField = this.props.diaphragmDesignFields[field].dependsOn;

			if( ! this.state.filters[dependentField] )
			{
				disabled = true;
			}
		}

		return disabled;
	}*/

	getFilteredDecksBySelectedAttributes() {
		let results = {}

		// console.log('getFilteredDecksBySelectedAttributes: CURRENTLY DISABLED IN DeckDetailPanel.js');
		//return results;

		for (const deck in this.props.diaphragmDesignFieldsData) {
			const deckData = this.props.diaphragmDesignFieldsData[deck].value
			//console.log(deckData);

			let matched = true
			for (const field in this.state.filters) {
				if (deckData[field] !== this.state.filters[field]) {
					//console.log(deckData[field], ' !== ',  this.state.filters[field]);
					matched = false
				}
			}

			if (matched) {
				results[this.props.diaphragmDesignFieldsData[deck].id] = this.props.diaphragmDesignFieldsData
			}
		}

		return results
	}

	getDiaphragmDesignDetail() {
		const results = this.getFilteredDecksBySelectedAttributes()
		this.setState({
			matchingDocuments: results,
		})
	}

	render() {
		return (
			<div className="row u-bg-dark">
				<div className="u-site-width">
					<div className="u-margin-buffer u-margin-buffer--collapse-at-mobile">
						<div className="row-detail">
							<button
								className="button button--inverted row-detail__close"
								onClick={this.collapseDetail}>
								x
							</button>

							<div className="panels">
								<div className="panel panel--visible">
									<div className="panel__header">
										<a
											href={this.props.pdfDownloadLink}
											rel="noopener noreferrer"
											target="_blank"
											className="button u-float-right">
											Download PDF
										</a>
										<h2 className="u-underbar">Steel Deck Design Guide</h2>
									</div>

									<div className="panel__content-scroller">
										<div className="panel__content panel__content--min-width">
											<div className={'expandable-panel ' + this.state.panelState}>
												<div dangerouslySetInnerHTML={{ __html: this.props.activeDeckHtml }} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default DeckDetailPanel
