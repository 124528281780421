import React, { Component } from 'react'
import FilterTextInput from './FilterTextInput.js'
import FilterSelect from './FilterSelect.js'
import FilterRangeInput from './FilterRangeInput.js'
import FilterToggle from './FilterToggle.js'

class FilterHeader extends Component {
	constructor(props) {
		super()

		this.state = {
			formMode: props.formMode,
			loadTablesFilterFields: props.resultRowsFieldConfig,
			diaphragmDesignFilterFields: props.diaphragmDesignFieldConfig,
			subFiltersVisible: true,

			formModeFieldConfig: props.formMode === 'load-tables' ? props.fieldConfig : props.diaphragmDesignFieldConfig,
		}

		//console.log(props.formModeFieldConfig);
		//console.log(props.resultRowsFieldConfig);
		//console.log(props);

		this.getActiveTabClass = this.getActiveTabClass.bind(this)
		this.toggleFormMode = this.toggleFormMode.bind(this)
		this.subFiltersVisible = this.subFiltersVisible.bind(this)
		this.clearFilters = this.clearFilters.bind(this)
	}

	getUnits(field) {
		const units = this.props.resultRowsFieldConfig[field].units
		return units ? `(${units})` : ''
	}

	getActiveTabClass(tab) {
		return tab === this.props.formMode ? 'tab tab--active' : 'tab'
	}

	toggleFormMode(formMode) {
		this.props.toggleFormMode(formMode)
	}

	subFiltersVisible() {
		return this.state.formMode === 'load-tables' ? '' : 'u-hidden'
	}

	clearFilters() {
		this.props.clearFilters()
	}

	getStandardCatalogsLink() {
		return 'https://www.newmill.com/tables/load-tables.html'
		//const links = {
		//	'roof': 'https://www.newmill.com/pdfs/roof-decking.pdf',
		//	'form': 'https://www.newmill.com/tables/load-tables.html',
		//	'composite': 'https://www.newmill.com/tables/load-tables.html'
		//};
		//
		//return links[this.props.productType];
	}

	render() {
		return (
			<React.Fragment>
				<div className="u-site-width">
					<div className="u-margin-buffer">
						<div className="u-content-width">
							<h2 className="u-center u-capitalize form-title">{this.props.productType} Deck Selection Tool</h2>
						</div>
					</div>
				</div>

				<div className="form-mode-wrapper">
					<div className="u-site-width">
						<div className="u-margin-buffer form-mode-wrapper-inner">
							<div className="utility-links">
								<button
									className="button button--green"
									onClick={(e) => this.clearFilters('load-tables')}>
									Reset
								</button>
								<a
									className="button button--green"
									href={this.getStandardCatalogsLink()}
									target="_blank"
									rel="noopener noreferrer">
									Standard Catalogs
								</a>
							</div>

							<ul className="tabs tabs--simple">
								<li className={this.getActiveTabClass('load-tables')}>
									<button onClick={(e) => this.toggleFormMode('load-tables')}>{this.props.productType === 'composite' ? 'Standard Load Tables' : 'Load Tables'}</button>
								</li>
								{this.props.productType === 'composite' && 'long-span-is-ready' === 'not yet' && (
									<li className={this.getActiveTabClass('long-span-load-tables')}>
										<button onClick={(e) => this.toggleFormMode('long-span-load-tables')}>Long-Span Load Tables</button>
									</li>
								)}
								<li className={this.getActiveTabClass('diaphragm-design')}>
									<button onClick={(e) => this.toggleFormMode('diaphragm-design')}>Diaphragm Design</button>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="row row--padded u-bg-med meta-filters">
					<div className="u-site-width">
						<div className="u-margin-buffer">
							<div className="meta-filters__toggles">
								{this.props.formMode === 'load-tables' &&
									Object.keys(this.props.resultRowsFieldConfig).map((field, index) => {
										return this.props.resultRowsFieldConfig[field].metaFilter ? (
											this.props.resultRowsFieldConfig[field].inputType === 'radio' ? (
												<FilterToggle
													key={'radio-group-' + field + index}
													field={field}
													fieldIndex={index}
													filters={this.props.filters}
													filterFields={this.props.resultRowsFieldConfig}
													fieldConfig={this.props.fieldConfig}
													resultRowsFieldConfig={this.props.resultRowsFieldConfig}
													applyFilterFn={this.props.applyFilterFn}
												/>
											) : (
												<div
													key={'select-group-label-wrapper-' + field + index}
													className="select-wrapper">
													<label
														key={'select-group-label-' + field + index}
														className="meta-filter__label">
														{this.props.resultRowsFieldConfig[field].label}
													</label>
													<FilterSelect
														key={'select-group-' + field + index}
														field={field}
														filters={this.props.filters}
														filterFields={this.props.resultRowsFieldConfig}
														fieldConfig={this.props.fieldConfig}
														options={this.props.resultRowsFieldConfig[field].options}
														applyFilterFn={this.props.applyFilterFn}
													/>
												</div>
											)
										) : (
											false
										)
									})}

								{this.props.formMode === 'diaphragm-design' &&
									Object.keys(this.props.resultRowsFieldConfig).map((field, index) => {
										return this.props.resultRowsFieldConfig[field] && this.props.resultRowsFieldConfig[field].metaFilter ? (
											<div
												className="meta-filter"
												key={'meta-filter-label-' + field + index}>
												<label className="meta-filter__label">{this.props.resultRowsFieldConfig[field].label}</label>
												<FilterSelect
													key={'select-group-' + field + index}
													field={field}
													filters={this.props.filters}
													filterFields={this.props.resultRowsFieldConfig}
													fieldConfig={this.props.fieldConfig}
													options={this.props.resultRowsFieldConfig[field].options}
													applyFilterFn={this.props.applyFilterFn}
												/>
											</div>
										) : (
											false
										)
									})}
							</div>
						</div>
					</div>
				</div>

				{(this.state.formMode === 'load-tables' || (this.props.diaphragmDesignFilters && this.props.diaphragmDesignFilters.deckType && this.props.diaphragmDesignFilters.minYieldStressForGrade)) && (
					<React.Fragment>
						<div className="row u-bg-med">
							<div className="u-site-width">
								<div className="u-margin-buffer">
									<div className={'data-row data-row__header data-row--inputs data-row--' + this.props.productType}>
										{this.props.formMode === 'load-tables' &&
											Object.keys(this.props.resultRowsFieldConfig).map((field) => {
												if (this.props.resultRowsFieldConfig[field].primaryFilter) {
													switch (this.props.resultRowsFieldConfig[field].inputType) {
														case 'select':
															return (
																<div
																	className="data-row__cell"
																	key={'th-control-' + field}>
																	<label className="data-row__label data-row__mobile-display">{this.props.resultRowsFieldConfig[field].label}</label>
																	<FilterSelect
																		field={field}
																		filters={this.props.filters}
																		filterFields={this.props.resultRowsFieldConfig}
																		fieldConfig={this.props.fieldConfig}
																		options={this.props.resultRowsFieldConfig[field].options}
																		applyFilterFn={this.props.applyFilterFn}
																	/>
																</div>
															)
															break

														case 'radio':
															return (
																<div
																	className="data-row__cell"
																	key={'th-control-' + field}>
																	<FilterToggle
																		key={'radio-group-' + field}
																		field={field}
																		filters={this.props.filters}
																		filterFields={this.props.resultRowsFieldConfig}
																		fieldConfig={this.props.fieldConfig}
																		resultRowsFieldConfig={this.props.resultRowsFieldConfig}
																		applyFilterFn={this.props.applyFilterFn}
																	/>
																</div>
															)
															break

														case 'range':
															return (
																<div
																	className="data-row__cell"
																	key={'th-control-' + field}>
																	<label className="data-row__label data-row__mobile-display">{this.props.resultRowsFieldConfig[field].label}</label>

																	<FilterRangeInput
																		field={field}
																		filters={this.props.filters}
																		filterFields={this.props.resultRowsFieldConfig}
																		fieldConfig={this.props.fieldConfig}
																		resultRowsFieldConfig={this.props.resultRowsFieldConfig}
																		applyFilterFn={this.props.applyFilterFn}
																	/>
																</div>
															)
															break

														case 'text':
															return (
																<div
																	className="data-row__cell"
																	key={'th-control-' + field}>
																	<label className="data-row__label data-row__mobile-display">{this.props.resultRowsFieldConfig[field].label}</label>
																	<FilterTextInput
																		field={field}
																		filters={this.props.filters}
																		filterFields={this.props.resultRowsFieldConfig}
																		fieldConfig={this.props.fieldConfig}
																		resultRowsFieldConfig={this.props.resultRowsFieldConfig}
																		applyFilterFn={this.props.applyFilterFn}
																	/>
																</div>
															)
															break

														default:
															return false
													}
												}

												return false
											})}

										{this.props.formMode === 'diaphragm-design' &&
											Object.keys(this.props.resultRowsFieldConfig).map((field) => {
												if (this.props.resultRowsFieldConfig[field].primaryFilter) {
													return (
														<div
															className="data-row__cell"
															key={'th-control-' + field}>
															<label className="data-row__label">{this.props.resultRowsFieldConfig[field].label}</label>
															<FilterSelect
																field={field}
																filters={this.props.filters}
																filterFields={this.props.resultRowsFieldConfig}
																fieldConfig={this.props.fieldConfig}
																options={this.props.resultRowsFieldConfig[field].options}
																applyFilterFn={this.props.applyFilterFn}
															/>
														</div>
													)
												} else {
													return false
												}
											})}
									</div>
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}

export default FilterHeader
