import React, { Component } from 'react';
import PageWrapper from '../PageWrapper.js';
import Filter from '../Filter.js';

export default class FormDeck extends Component
{
	render()
	{
		return <PageWrapper
			showTabs="true"
			activeTab="form"
			banner="decktools"
			pageTitle={ <React.Fragment>Deck <span>Tools</span></React.Fragment> } >
				<Filter formName="Form Deck" productType="Form_Deck" />
		</PageWrapper>
	}
}
