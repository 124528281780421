import React, { Component } from 'react';
import DeckDetailPanel from './DeckDetailPanel.js';
import Config from './Config.js';

class FilterResultRows extends Component
{
	constructor(props)
	{
		super();

		this.expandDetail = this.expandDetail.bind(this);
		this.collapseDetail = this.collapseDetail.bind(this);
		this.getPdfDownloadLink = this.getPdfDownloadLink.bind(this);

		this.state = {
			'resultRows': props.resultRows,
			'resultRowsFieldConfig': props.resultRowsFieldConfig,
			'activeItemId': props.activeItemId,
			'formMode': props.formMode
		};
	}

	getPdfDownloadLink(row)
	{
		const config = new Config();

		// const documentId = row.id;
		// can't just read row.id because that won't take into consideration the meta filter values we may have set
		// like design method (ASD/LRFD), etc


		const documentId = (this.props.formMode === config.formMode.DIAPHRAGM_DESIGN)
			? this.state.activeItemId
			: this.props.getDocumentIdFn(row);

		const productTypeMap = {
			'Form_Deck': 'form',
			'Roof_Deck': 'roof',
			'Composite_Deck': 'composite'
		};

		let productType = config.formMode.DIAPHRAGM_DESIGN;

		if( this.props.formMode === config.formMode.LOAD_TABLES )
		{
			productType = productTypeMap[this.props.productType];
		}

		// need to pass roof/form/composite to the pdf renderer, even when it's diaphragm designn
		// so that the product type can be reflected in user criteria
		const productName = productTypeMap[this.props.productType];

		let protocol = 'https://';
		let host = window.location.host;

		if( host.indexOf('localhost') !== -1)
		{
			protocol = 'http://';
			host = 'tools.newmill.cm';
		}
		else if (host.indexOf('centrifuge') !== -1) {
			protocol = 'http://';
		}

		const domain = protocol + host;

		// because 1.5FDI doesn't exist in the database - it's just a 1.5FD. But we still want the PDF generator
		// to know what the user selected
		let deckTypeAlias = '';
		if( this.props.filters.deckType )
		{
			deckTypeAlias = '&deckTypeAlias=' + this.props.filters.deckType;
		}
		return domain + "/create-pdf.php?documentId=" + documentId + "&type=" + productType + '&productName=' + productName + deckTypeAlias;
	}

	expandDetail(e)
	{
		e.preventDefault();

		e.currentTarget.classList.add('icon--loading');

		const deckId = e.currentTarget.getAttribute('data-row-id');

		this.setState({
			'activeItemId': deckId
		});

		// get the deck data
		const deck = this.props.getDeckDataFn(deckId);

		this.props.selectDeckFn(deck.id, deck.minYieldStressForGrade.value);
	}

	collapseDetail(e)
	{
		e.preventDefault();
		this.props.selectDeckFn(null);
	}

	render()
	{
		return (

			<React.Fragment>

				{ this.props.isFiltered && (this.props.activeItemId === null || this.props.activeItemId === this.props.row.id) &&
					<div className="row u-bg-lite row--collapsed" data-row-id={this.props.row.id} data-deck-id={this.props.row.id} onClick={(e) => {this.expandDetail(e)}}>
						<div className="u-site-width">
							<div className="u-margin-buffer">
								<div className={'data-row data-row--' + this.props.productTypeShortName } key={"row-" + this.props.row.id }>
									{
										Object.keys(this.props.row).map( (field, fieldIndex) => {
											return this.props.resultRowsFieldConfig[field] && ! this.props.resultRowsFieldConfig[field].metaFilter && <div className="data-row__cell" key={"cell-" + this.props.row.id + '-' + fieldIndex}>
													<div className="data-row__label" key={"label-" + this.props.row.id + '-' + fieldIndex}>{this.props.resultRowsFieldConfig[field].label}</div>
                                                    <span dangerouslySetInnerHTML={{__html: this.props.row[field].displayValue || this.props.row[field].value }}></span>
												</div>
										})
									}
									<div className="data-row__controls" key={"expend-detail-" + this.props.row.id }>
										<button className="data-row__control" data-row-id={this.props.row.id} data-deck-id={this.props.row.id} onClick={(e) => {this.expandDetail(e)}}>View Details</button>
									</div>

								</div>
							</div>
						</div>
					</div>
				}

				{ this.props.isFiltered && this.props.activeItemId !== null && this.props.activeItemId === this.props.row.id && <DeckDetailPanel
					gage={this.props.gage}
					productType={this.props.productType}
					itemId={this.state.activeItemId}

					// A method for the DeckDetail component to communicate selections back to Filter.js
					activeDeckHtml={this.props.activeDeckHtml}

					selectDeckFn={this.props.selectDeckFn}
					getDeckDataFn={this.props.getDeckDataFn}

					pdfDownloadLink={this.getPdfDownloadLink(this.props.row)}

					/>
				}

			</React.Fragment>
		)
	}

}

export default FilterResultRows;