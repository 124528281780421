class Config {
	constructor() {
		this.formConfig = {
			//
			// Meta Filter
			// A filter used to distinguish between families of products
			// This will include Design Method, Gage & Concrete weight. These only display on Load Table filter views
			//
			//
			// Primary Filter: A filter used to reduce the set of visible results
			//

			// ROOF
			Roof_Deck: {
				// used to clear forward filters - organized by index matching the order in which each is established in the fields map below
				'load-tables_FieldOrderMap': ['designMethod', 'deckType', 'gage', 'minYieldStressForGrade'],

				// DECK
				fields: {
					designMethod: {
						metaFilter: true,
						label: 'Design Method',
						inputType: 'radio',
						defaultValue: 'ASD',
						options: {},
						isToggle: true,
						dontFilter: true,
					},

					//'defaultConstructionLoads': {
					// 	'metaFilter': true,
					//	'label': 'Construction Load Criteria',
					//	'inputType': 'radio',
					//	'defaultValue': 'SDI RD Std (Ind Std)',
					//	'options': {},
					//	'stripFromFieldValue':' (Ind Std)'
					//},

					deckType: {
						label: 'Deck Type',
						inputType: 'select',
						primaryFilter: true,
						options: {
							'1.0RD': { text: '1.0RD, 1.0RDV', value: '1.0RD' },
							B: { text: 'B, BI, BV, BIV', value: 'B' },
							BA: { text: 'BA, BIA', value: 'BA' },
							F: { text: 'F', value: 'F' },
							NW32: { text: 'NW32&trade;', value: 'NW32' },
							NW32A: { text: 'NW32A&trade;', value: 'NW32A' },
							NW32I: { text: 'NW32I&trade;', value: 'NW32I' },
							NW32IA: { text: 'NW32IA&trade;', value: 'NW32IA' },
							N: { text: 'N', value: 'N' },
							NA: { text: 'NA', value: 'NA' },
							'2.0VLSES': { text: '2.0VLSES', value: '2.0VLSES' },
							'2.0VLSESA': { text: '2.0VLSESA', value: '2.0VLSESA' },
							'2.0VSES': { text: '2.0VSES', value: '2.0VSES' },
							'2.0VSESA': { text: '2.0VSESA', value: '2.0VSESA' },
							'3.5VLS': { text: '3.5VLS', value: '3.5VLS' },
							'3.5VLSA': { text: '3.5VLSA', value: '3.5VLSA' },
						},
						optionsOrderMap: ['1.0RD', 'B', 'BA', 'F', 'NW32', 'NW32A', 'NW32I', 'NW32IA', 'N', 'NA', '2.0VLSES', '2.0VLSESA', '2.0VSES', '2.0VSESA', '3.5VLS', '3.5VLSA'],
					},

					//'gage': 					{'label': 'Gage', 						'inputType': 'radio', 'primaryFilter': true, 'options': {}, 'dontValidate': true },
					gage: {
						primaryFilter: true,
						label: 'Gage',
						defaultValue: 'Standard',
						inputType: 'radio',
						options: {},
						dontValidate: true,
					},

					minYieldStressForGrade: {
						primaryFilter: true,
						label: 'Min Yield Stress for Grade (ksi)',
						inputType: 'select',
						options: {},
					},
				},

				// require that filters be selected for these fields before results can be shown
				'diaphragm-design_RequiredForResults': ['deckType', 'gage', 'minYieldStressForGrade'],
				'diaphragm-design_FieldOrderMap': ['deckType', 'gage', 'minYieldStressForGrade', 'netUplift', 'supportFasteners', 'sideLapFasteners'],

				// DIAPHRAGM DESIGN
				diaphragmDesignFieldConfig: {
					// TODO
					// need to add Deck Type
					// need to populate field values from api call
					// need to populate mysfg values from api call

					deckType: {
						metaFilter: true,
						label: 'Deck Type',
						inputType: 'select',
						dontFilter: true,
						requiredMetaField: true,
						options: {
							'1.0RD': { text: '1.0RD, 1.0RDV', value: '1.0RD' },

							B: { text: 'B, BV', value: 'B' },
							BA: { text: 'BA', value: 'BA' },
							BI: { text: 'BI, BIV', value: 'BI' },
							BIA: { text: 'BIA', value: 'BIA' },
							F: { text: 'F', value: 'F' },
							NW32: { text: 'NW32&trade;', value: 'NW32' },
							NW32A: { text: 'NW32A&trade;', value: 'NW32A' },
							NW32I: { text: 'NW32I&trade;', value: 'NW32I' },
							NW32IA: { text: 'NW32IA&trade;', value: 'NW32IA' },
							N: { text: 'N', value: 'N' },
							NA: { text: 'NA', value: 'NA' },
							'2.0VLSES': { text: '2.0VLSES', value: '2.0VLSES' },
							'2.0VLSESA': { text: '2.0VLSESA', value: '2.0VLSESA' },
							'2.0VSES': { text: '2.0VSES', value: '2.0VSES' },
							'2.0VSESA': { text: '2.0VSESA', value: '2.0VSESA' },
							'3.5VLS': { text: '3.5VLS', value: '3.5VLS' },
							'3.5VLSA': { text: '3.5VLSA', value: '3.5VLSA' },
						},
					},

					//<option value="B">B, BV</option>
					//<option value="BA">BA</option>
					//<option value="BI">BI, BIV</option>
					//<option value="BIA">BIA</option>
					gage: { metaFilter: true, label: 'Gage', inputType: 'select', dontFilter: true, options: {}, dependsOn: 'deckType', requiredMetaField: true },

					// primary filters
					minYieldStressForGrade: {
						metaFilter: true,
						label: 'Min Yield Stress for Grade (ksi)',
						inputType: 'select',
						dontFilter: true,
						dependsOn: 'gage',
						options: {},
						requiredMetaField: true,
					},

					netUplift: { primaryFilter: true, label: 'Net Uplift (psf)', inputType: 'select', options: {}, replacePlaceholderValue: 'NO_NETUPLIFT', dependsOn: 'minYieldStressForGrade' },
					supportFasteners: { primaryFilter: true, label: 'Support Fastener', inputType: 'select', options: {}, dependsOn: 'minYieldStressForGrade', compoundFields: ['support2', 'support3', 'support4'] },
					sideLapFasteners: { primaryFilter: true, label: 'Side Lap Fastener', inputType: 'select', options: {}, dependsOn: 'minYieldStressForGrade', compoundFields: ['sideLap2'] },
				},
			},

			// FORM
			Form_Deck: {
				// used to clear forward filters - organized by index matching the order in which each is established in the fields map below
				'load-tables_FieldOrderMap': ['designMethod', 'concreteUnitWeight', 'concreteStrength', 'uniformConstructionLiveLoad', 'unshoredSpans', 'totalSlabDepths', 'deckType', 'gage', 'minYieldStressForGrade'],

				// DECK
				fields: {
					designMethod: { metaFilter: true, options: {}, label: 'Design Method', inputType: 'radio', defaultValue: 'ASD', isToggle: true, dontFilter: true, dontClear: true },
					concreteUnitWeight: { metaFilter: true, options: {}, label: 'Concrete Weight (pcf)', inputType: 'radio', defaultValue: 'NW (145)', isToggle: true, dontFilter: true, dontClear: true },
					concreteStrength: { metaFilter: true, options: {}, label: 'Concrete Strength (psi)', inputType: 'select', dontFilter: true, requiredMetaField: true, dontClear: true },
					uniformConstructionLiveLoad: { metaFilter: true, options: {}, label: 'Uniform Construction Live Load (psf)', inputType: 'select', dontFilter: true, requiredMetaField: true, dontClear: true },
					// was 'range'
					unshoredSpans: {
						primaryFilter: true,
						label: 'Clear Span',
						inputType: 'text',
						placeholderText: 'ft',
						rangeLow: 2,
						rangeHigh: 22,
						keyValue: true,
						options: {},
						staticInstructionsText: 'Whole ft. Incr. 2 thru 22',
						units: 'ft',
						validationMethod: 'integer',
					},

					totalSlabDepths: {
						primaryFilter: true,
						label: 'Total Slab Depth',
						inputType: 'text',
						placeholderText: 'in',
						rangeLow: 2.5,
						rangeHigh: 13.5,
						keyValue: true,
						options: {},
						staticInstructionsText: 'Dec. In. (.5 in. Incr.) 2.5 thru 13.5',
						units: 'in',
						validationMethod: 'decimal',
					},

					deckType: {
						primaryFilter: true,
						label: 'Deck Type',
						inputType: 'select',
						options: {
							'0.6FD': { text: '0.6FD, 0.6FDV', value: '0.6FD' },
							'1.0FD': { text: '1.0FD, 1.0FDV', value: '1.0FD' },
							'1.5FD': { text: '1.5FD', value: '1.5FD' },
							'1.5FDR': { text: '1.5FDR, 1.5FDI, 1.5FDV, 1.5FDIV', value: '1.5FDR' },
							//'1.5FDI': {'text': '1.5FDI, 1.5FDIV', 'value': '1.5FDI'},
							'2.0FD': { text: '2.0FD', value: '2.0FD' },
							'3.0FD': { text: '3.0FD', value: '3.0FD' },
						},
					},

					gage: {
						primaryFilter: true,
						options: {},
						label: 'Gage',
						inputType: 'radio',
						defaultValue: 'Standard',
						isToggle: true,
					},

					minYieldStressForGrade: {
						primaryFilter: true,
						options: {},
						label: 'Min Yield Stress for Grade (ksi)',
						inputType: 'select',
					},
				},

				'diaphragm-design_FieldOrderMap': ['deckType', 'gage', 'minYieldStressForGrade', 'fillType', 'supportFasteners', 'sideLapFasteners'],

				// DIAPHRAGM DESIGN
				diaphragmDesignFieldConfig: {
					deckType: {
						metaFilter: true,
						label: 'Deck Type',
						inputType: 'select',
						dontFilter: true,
						requiredMetaField: true,
						options: {
							'0.6FD': { text: '0.6FD, 0.6FDV', value: '0.6FD' },
							'1.0FD': { text: '1.0FD, 1.0FDV', value: '1.0FD' },
							'1.5FD': { text: '1.5FD', value: '1.5FD' },
							'1.5FDR': { text: '1.5FDR, 1.5FDV', value: '1.5FDR' },
							'1.5FDI': { text: '1.5FDI, 1.5FDIV', value: '1.5FDI' },
							'2.0FD': { text: '2.0FD', value: '2.0FD' },
							'3.0FD': { text: '3.0FD', value: '3.0FD' },
						},
					},

					gage: { metaFilter: true, label: 'Gage', inputType: 'select', options: {}, dependsOn: 'deckType', dontFilter: true, requiredMetaField: true },

					// primary filters
					minYieldStressForGrade: { metaFilter: true, label: 'Min Yield Stress for Grade (ksi)', inputType: 'select', dependsOn: 'gage', options: {}, dontFilter: true, requiredMetaField: true },

					fillType: { primaryFilter: true, label: 'Concrete Fill', inputType: 'select', options: {}, stripFromFieldValue: '_2_Y', dependsOn: 'minYieldStressForGrade' },

					// composite for all four
					supportFasteners: { primaryFilter: true, label: 'Support Fastener', inputType: 'select', options: {}, dependsOn: 'minYieldStressForGrade', compoundFields: ['support2', 'support3', 'support4'] },

					// composite for all 2
					sideLapFasteners: { primaryFilter: true, label: 'Side Lap Fastener', inputType: 'select', options: {}, dependsOn: 'minYieldStressForGrade', compoundFields: ['sideLap2'] },

					//'concreteAbove': 	{ 'label': 'Concrete Above', 	'inputType': 'radio',  'options': {}, 'dependsOn': 'fillType', 'defaultValue': 'No' },
					//'filledDeck': 		{ 'label': 'Filled Deck', 		'inputType': 'radio',  'options': {}, 'dependsOn': 'concreteAbove', 'defaultValue': 'No' }
				},
			},

			// COMPOSITE
			Composite_Deck: {
				// used to clear forward filters - organized by index matching the order in which each is established in the fields map below
				'load-tables_FieldOrderMap': ['designMethod', 'concreteUnitWeight', 'concreteStrength', 'uniformConstructionLiveLoad', 'unshoredSpans', 'totalSlabDepths', 'deckType', 'gage', 'minYieldStressForGrade'],

				// DECK
				fields: {
					designMethod: { metaFilter: true, label: 'Design Method', defaultValue: 'ASD', inputType: 'radio', options: {}, dontValidate: true, dontFilter: true, dontClear: true },
					concreteUnitWeight: { metaFilter: true, label: 'Concrete Weight (pcf)', defaultValue: 'NW (145)', inputType: 'radio', options: {}, dontValidate: true, dontFilter: true, dontClear: true },
					concreteStrength: { metaFilter: true, label: 'Concrete Strength (psi)', inputType: 'select', options: {}, dontFilter: true, requiredMetaField: true, dontClear: true },
					uniformConstructionLiveLoad: { metaFilter: true, label: 'Uniform Construction Live Load (psf)', inputType: 'select', options: {}, dontFilter: true, requiredMetaField: true, dontClear: true },

					//'unshoredSpans': 				{'primaryFilter': true, 'label': 'Unshored Spans', 'inputType': 'range', 'rangeHigh': 0, 'rangeLow': 0, 'keyValue': true, 'options': {} },
					unshoredSpans: { primaryFilter: true, label: 'Clear Span', inputType: 'text', placeholderText: 'ft', rangeHigh: 23, rangeLow: 5, keyValue: true, options: {}, units: 'ft', staticInstructionsText: 'Whole ft. Incr. 5 thru 23', validationMethod: 'integer' },
					totalSlabDepths: { primaryFilter: true, label: 'Total Slab Depth', inputType: 'text', placeholderText: 'in', rangeHigh: 13.5, rangeLow: 3.5, keyValue: true, options: {}, units: 'in', staticInstructionsText: 'Dec. In. (.5 in. Incr.) 3.5 thru 13.5', validationMethod: 'decimal' },
					deckType: {
						primaryFilter: true,
						label: 'Deck Type',
						inputType: 'select',
						options: {
							'1.5CD': { text: '1.5CD, 1.5CDI', value: '1.5CD' },
							'1.5CDR': { text: '1.5CDR', value: '1.5CDR' },
							'2.0CD': { text: '2.0CD', value: '2.0CD' },
							'3.0CD': { text: '3.0CD', value: '3.0CD' },
						},
					},
					gage: { primaryFilter: true, label: 'Gage', defaultValue: 'Standard', inputType: 'radio', options: {}, dontValidate: true },
					minYieldStressForGrade: { primaryFilter: true, label: 'Min Yield Stress for Grade (ksi)', inputType: 'select', options: {} },
				},

				'diaphragm-design_FieldOrderMap': ['deckType', 'gage', 'minYieldStressForGrade', 'fillType', 'supportFasteners', 'sideLapFasteners'],

				// DIAPHRAGM DESIGN
				diaphragmDesignFieldConfig: {
					deckType: {
						metaFilter: true,
						label: 'Deck Type',
						inputType: 'select',
						dontFilter: true,
						requiredMetaField: true,
						options: {
							'1.5CD': { text: '1.5CD', value: '1.5CD' },
							'1.5CDI': { text: '1.5CDI', value: '1.5CDI' },
							'1.5CDR': { text: '1.5CDR', value: '1.5CDR' },
							'2.0CD': { text: '2.0CD', value: '2.0CD' },
							'3.0CD': { text: '3.0CD', value: '3.0CD' },

							'2.0VLSESC': { text: '2.0VLSESC', value: '2.0VLSESC' },
							'2.0VLSESCA': { text: '2.0VLSESCA', value: '2.0VLSESCA' },
							'2.0VSESC': { text: '2.0VSESC', value: '2.0VSESC' },
							'2.0VSESCA': { text: '2.0VSESCA', value: '2.0VSESCA' },
							'3.5VLSC': { text: '3.5VLSC', value: '3.5VLSC' },
							'3.5VLSCA': { text: '3.5VLSCA', value: '3.5VLSCA' },
						},
					},

					gage: { metaFilter: true, label: 'Gage', inputType: 'select', options: {}, dependsOn: 'deckType', dontFilter: true, requiredMetaField: true },

					// primary filters
					minYieldStressForGrade: { metaFilter: true, label: 'Min Yield Stress for Grade (ksi)', inputType: 'select', options: {}, dependsOn: 'gage', dontFilter: true, requiredMetaField: true },

					fillType: { primaryFilter: true, label: 'Concrete Fill', inputType: 'select', options: {}, stripFromFieldValue: '_2_Y', dependsOn: 'minYieldStressForGrade' },
					supportFasteners: { primaryFilter: true, label: 'Support Fastener', inputType: 'select', options: {}, dependsOn: 'minYieldStressForGrade', compoundFields: ['support2', 'support3', 'support4'] },
					sideLapFasteners: { primaryFilter: true, label: 'Side Lap Fastener', inputType: 'select', options: {}, dependsOn: 'minYieldStressForGrade', compoundFields: ['sideLap2'] },
				},
			},
		}

		this.productTypeMap = {
			Form_Deck: 'form',
			Roof_Deck: 'roof',
			Composite_Deck: 'composite',
		}

		this.formMode = {
			DIAPHRAGM_DESIGN: 'diaphragm-design',
			LOAD_TABLES: 'load-tables',
		}

		this.ENV = process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'development' ? 'DEV' : 'PROD'

		this.baseUrl = 'https://tools.newmill.com'

		const hostname = window.location.hostname === 'localhost' ? 'tools.newmill.cm' : window.location.hostname

		this.baseUrl = window.location.protocol + '//' + hostname
		// if( window.location.hostname !== 'tools.newill.com')
		// this.baseUrl = this.ENV === 'PROD'
		// 	? 'https://tools.newmill.com'
		// 	: 'http://tools.newmill.cm';
	}

	getEnvironment() {
		return this.ENV
	}

	getBaseUrl() {
		return this.baseUrl
	}

	getDeckConfig(deckType) {
		return this.formConfig[deckType] || false
	}

	getDiaphragmDesignConfig(deckType) {
		return this.formConfig[deckType]['diaphragmDesignFieldConfig'] || false
	}

	getDeckTypeShortName(deckType) {
		return this.productTypeMap[deckType]
	}
}

export default Config
