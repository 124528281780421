// import 'babel-polyfill'
import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom'

import DesignTools from './components/views/DesignTools.js'
import FormDeck from './components/views/FormDeck.js'
import RoofDeck from './components/views/RoofDeck.js'
import CompositeDeck from './components/views/CompositeDeck.js'

import './components/css/global.css'

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<div>
		<BrowserRouter>
			<React.Fragment>
				<Routes>
					<Route
						exact
						path="/"
						element={<DesignTools />}
					/>
					<Route
						exact
						path="/design-tools/"
						element={<DesignTools />}
					/>
					<Route
						exact
						path="/deck-tools/"
						element={<RoofDeck />}
					/>
					<Route
						exact
						path="/deck-tools/form-deck/"
						element={<FormDeck />}
					/>
					<Route
						exact
						path="/deck-tools/roof-deck/"
						element={<RoofDeck />}
					/>
					<Route
						exact
						path="/deck-tools/composite-deck/"
						element={<CompositeDeck />}
					/>
				</Routes>
			</React.Fragment>
		</BrowserRouter>
	</div>
)
