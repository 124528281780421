import React, { Component } from 'react';

class FilterTextInput extends Component
{

	constructor(props)
	{
        super();
        this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.shouldBeDisabled = this.shouldBeDisabled.bind(this);

		this.inputElementRef = React.createRef();
		this.getInstructionsText = this.getInstructionsText.bind(this);
        this.getRangeValues = this.getRangeValues.bind(this);
        
        this.lastBlurEventTime = null;

		this.state = {
            'disabled': false,
            'isManagingFocus': false
		}
	}

	defaultValue()
	{
		return this.props.filters && this.props.filters[this.props.field]
			? this.props.filters[this.props.field]
			: '';
	}

	// iterate through all the fields in fieldConfig
	// determine whether any of them is a Meta Filter, which would be required before a Primary Filter can be selected
	// if any Meta Filter is missing, return disabled
	shouldBeDisabled()
	{
		let shouldBeDisabled = false;

		for( const field in this.props.fieldConfig)
		{
			const filterField = this.props.fieldConfig[field];

			if( filterField.requiredMetaField && ! this.props.filters[field] )
			{
				shouldBeDisabled = true;
			}
		}
		return shouldBeDisabled;
	}

	setDisabledState()
	{
		// when a field has a dependency
		if( this.props.filterFields[this.props.field].primaryFilter )
		{
			// get the current state
			const currentDisabledState = this.state.disabled;

			// determine whether the required meta filters have already been selected by the user
			const shouldBeDisabled = this.shouldBeDisabled();

			// only change state when the current state doesn't match the new state
			if( currentDisabledState !== shouldBeDisabled )
			{
				//console.log('set to disabled');
				this.setState({
					'disabled': shouldBeDisabled
				});
			}
		}
	}

	getInstructionsText()
	{
		let instructionsText = '';
		const field = this.props.field;
		const fieldConfig = this.props.fieldConfig[field];

		if( fieldConfig.staticInstructionsText )
		{
			instructionsText = fieldConfig.staticInstructionsText;
		}
		else if( fieldConfig.instructionsText )
		{
			instructionsText = fieldConfig.instructionsText;
			const [rangeLow, rangeHigh] = this.getRangeValues();

			instructionsText = `${instructionsText} ${rangeLow} thru ${rangeHigh}`;
		}
		return instructionsText;
	}

	getRangeValues()
	{
		const field = this.props.field;
		const fieldConfig = this.props.fieldConfig[field];

		const divisor = fieldConfig.units === 'ft' ? 12 : false;
		let rangeLow = fieldConfig.rangeLow;
		let rangeHigh = fieldConfig.rangeHigh;

        return [rangeLow, rangeHigh];
    }
    
    handleFocus(e)
    {
        this.inputElementRef.current.select();
    }

	handleBlur(e)
	{
        const currentTime = new Date().getTime();
        const fieldName = this.inputElementRef.current.getAttribute('name').replace('-value', '');
        const fieldValue = this.inputElementRef.current.value;

        if( typeof this.props.applyFilterFn === 'function' && (!this.lastBlurEventTime) || (this.lastBlurEventTime && currentTime > this.lastBlurEventTime + 2000) ) {
            const [rangeLow, rangeHigh] = this.getRangeValues();
            
            if(  (fieldValue < rangeLow || fieldValue > rangeHigh) )
            {
                if( fieldValue !== '') 
                {
                    alert(`Please provide a value between ${rangeLow} and ${rangeHigh}`);
                    this.inputElementRef.current.focus();
                }
                
                this.props.applyFilterFn(fieldName, false);
            }
            else
            {
                this.props.applyFilterFn(fieldName, fieldValue);
            }
        }

        this.lastBlurEventTime = currentTime;
	}

	componentWillUpdate()
	{
		this.inputElementRef.current.value = this.defaultValue();
	}

	componentDidUpdate()
	{
		this.setDisabledState();
	}

	componentDidMount()
	{
		this.setDisabledState();
	}

	render()
	{
		return (
			<React.Fragment>

				<input
					ref={this.inputElementRef}
					type="text"
					className="u-input-small u-center"
					key={this.props.field + '-value'}
					name={this.props.field + '-value'}
					id={this.props.field + '-value'}
					disabled={this.state.disabled}
					//onKeyDownChange={ this.preventInvalidChars }
					//onKeyDown={ this.handleChange }
                    onBlur={ this.handleBlur }
                    onFocus={ this.handleFocus }
					placeholder={this.props.fieldConfig[this.props.field].placeholderText || ''}

					/>
				{
					(this.props.fieldConfig[this.props.field].instructionsText || this.props.fieldConfig[this.props.field].staticInstructionsText )&&
					<div className="instructionalText">
						{this.getInstructionsText()}
					</div>
				}
			</React.Fragment>
		)
	}
}

export default FilterTextInput;