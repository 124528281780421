import React, { Component } from 'react';
import PageWrapper from '../PageWrapper.js';

export default class DesignTools extends Component
{
	render()
	{
		return <PageWrapper
			activeTab="roof"
			banner="designtools"
			pageTitle={ <React.Fragment>Design <span>Tools</span></React.Fragment> } >

				<div className="u-site-width">
					<div className="u-margin-buffer">
						<div className="u-content-width">
							<h2 className="u-capitalize">Transform the way you design</h2>
						</div>
					</div>
				</div>

				<div className="landing">
					<div className="landing__panel landing__panel--decktools">

						<div className="dialog dialog--green">
							<div className="dialog__title">Deck <span>Tools</span></div>
							<div className="dialog__body-wrapper">
                                <div className="dialog__body">
                                    <p>
                                        Enter specifications<br/>
                                        Generate load tables or <br/>
                                        diaphragm design
                                    </p>
                                </div>

                                <div className="dialog__actions">
                                    <a href="/deck-tools/" className="button button--gray button--landing">Get Started</a>
                                </div>

                            </div> 
						</div>

					</div>

					<div className="landing__panel landing__panel--joisttools">

						<div className="dialog dialog--red">
							<div className="dialog__title">Standard Joist <span>Tool</span></div>
							<div className="dialog__body-wrapper">
                                <div className="dialog__body">
                                    Enter specifications<br/>
                                    Generate load table
                                </div>

                                <div className="dialog__actions">
                                    <a href="https://www.newmill.com/design-tools/tools/standard-joist-tools.html" className="button button--gray button--landing" target="_blank" rel="noreferrer noopener">Get Started</a>
                                </div>
                            </div>
						</div>

					</div>




                    <div className="landing__panel landing__panel--decktools">

                        <div className="dialog dialog--blue">
                            <div className="dialog__title">Special Profile <span>Tool</span></div>
                            <div className="dialog__body-wrapper">
                                <div className="dialog__body">
                                    Enter specifications <br/>
                                    Generate load table
                                </div>

                                <div className="dialog__actions">
                                    <a href="https://www.newmill.com/design-tools/tools/special-profile-tools.html" className="button button--gray button--landing" target="_blank" rel="noreferrer noopener">Get Started</a>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="landing__panel landing__panel--joisttools">

                        <div className="dialog dialog--purple">
                            <div className="dialog__title">Joist Girder <span>Tool</span></div>
                            <div className="dialog__body-wrapper">
                                <div className="dialog__body">
                                    Enter specifications<br/>
                                    Generate load table
                                </div>

                                <div className="dialog__actions">
                                    <a href="https://www.newmill.com/design-tools/tools/girders-tool.html" className="button button--gray button--landing" target="_blank" rel="noreferrer noopener">Get Started</a>
                                </div>
                            </div>
                        </div>

                    </div>

				</div>

			<div className="u-site-width">
				<div className="u-margin-buffer">
					
                    <div className="tools-overview">
                        <p>With our newly expanded and comprehensive design tools, you have everything you need to build a better steel experience. You can generate load tables and diaphragm design tables customized specifically for your project. Updated to the latest industry standards for roof deck, composite deck and form deck, our deck tools can deliver more than 65,000 design possibilities, helping you to increase project performance and reduce project costs.</p>
				    </div>

				</div>
			</div>

		</PageWrapper>
	}
}